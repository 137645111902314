import React, { useState } from 'react';

import styles from './congratulationsJobApplication.module.scss';
import PopUpVerifyPhone from '../../../components/Talent/PopUpVerifyPhone';
import cross from '../../../img/Ctrate-form/cross_black.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CongratulationsJobApplication = () => {
  const { t } = useTranslation('common');
  const reasonsArray = [
    { desc: `${t('congratulationJob.congratulation1')}` },
    { desc: `${t('congratulationJob.congratulation2')}` },
    { desc: `${t('congratulationJob.congratulation3')}` },
    { desc: `${t('congratulationJob.congratulation4')}` },
  ];

  const detailsArray = [
    {
      important: `${t('detailsTrial.listImportant1')}`,
      desc: `${t('detailsTrial.listDesc1')}`,
    },
    {
      important: `${t('detailsTrial.listImportant2')}`,
      desc: `${t('detailsTrial.listDesc2')}`,
    },
    {
      important: `${t('detailsTrial.listImportant3')}`,
      desc: `${t('detailsTrial.listDesc3')}`,
    },
  ];

  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <section className={styles.congratulationsJobApplication}>
      <h1 className={styles.title}>{t('congratulationJob.title')}</h1>
      <ul className={styles.welcomeItems}>
        {reasonsArray.map((item, index) => (
          <li className={styles.welcomeItem} key={index}>
            <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M2 6.36842L6.03333 11L13 3' stroke='#219653' strokeWidth='2.5' strokeLinecap='round' />
            </svg>
            <aside>
              <p className={styles.welcomeItemDesc}>{item.desc}</p>
            </aside>
          </li>
        ))}
      </ul>
      <h2 className={styles.subTitle}>{t('congratulationJob.join')}</h2>
      <button className={styles.openPopUpBtn} type='button' onClick={() => setPopupOpen(true)}>
        {t('congratulationJob.tryBtn')}
      </button>
      {popupOpen && (
        <article className={styles.popupPhone}>
          <div className={styles.popUpBg} />
          <div className={styles.popUpContainer}>
            <hr className={styles.topLine} />
            <h1 className={styles.titlePopup}>{t('detailsTrial.title')}</h1>
            <h2 className={styles.subtitle}>{t('detailsTrial.subTitle')}</h2>
            <ul className={styles.welcomeItems}>
              {detailsArray.map((item, index) => (
                <li className={styles.welcomeItem} key={index}>
                  <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M2 6.36842L6.03333 11L13 3' stroke='#219653' strokeWidth='2.5' strokeLinecap='round' />
                  </svg>
                  <p className={styles.welcomeItemDesc}>
                    <span>{item.important}</span> {item.desc}
                  </p>
                </li>
              ))}
            </ul>
            <h2 className={styles.subTitle}>{t('detailsTrial.paidPeriod')}</h2>
            <Link className={styles.sendBtn} to='/talent/jobs'>
              {t('detailsTrial.free')}
            </Link>
          </div>
        </article>
      )}
    </section>
  );
};

export default CongratulationsJobApplication;
