import { flow, types, getSnapshot } from 'mobx-state-tree';
// import { api } from '../../api';
// import axios from 'axios';

import employerTemplates from '../ChatMocData/chatEmploerTalent.json';
import templateTalent from '../ChatMocData/chatTalentEmployer.json';

const { model, frozen } = types;
const Message = model('Message', {
  message: types.string,
  sender: types.string,
  time: types.string,
});

// console.log('employerTemplates', employerTemplates);

export const ChatStore = model('ChatStore', {
  click: types.boolean,
  messages: types.array(Message),
  employerTemplatesMocData: types.array(types.string),
  templateTalentMocData: types.array(types.string),
}).actions((self) => ({
  setClick(value: boolean) {
    self.click = value;
  },
  setMessages(newMessage) {
    self.messages.push(newMessage);
    console.log('messages ++++', getSnapshot(self.messages));
  },
  afterCreate() {
    self.employerTemplatesMocData.replace(employerTemplates.employerTemplates);
    self.templateTalentMocData.replace(templateTalent.templateTalent);
  },

  /*afterCreate() {
    Promise.all([self.getContent(), self.getPosts(), self.getCategories()]).then(() => {
      self.getHomePage();
    });
  },*/
}));

export default ChatStore;
