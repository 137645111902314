import React, { createContext } from 'react';
import { types, Instance } from 'mobx-state-tree';
import { useLocalObservable, enableStaticRendering } from 'mobx-react-lite';
import { HomePageStore } from './pages/HomePage/homePage.store';
import makeInspectable from 'mobx-devtools-mst';
import { ChatStore } from './StoreMobX/chat.store';

enableStaticRendering(typeof window === 'undefined');

const { model } = types;

export const RootStore = model('RootStore', {
  homePageStore: HomePageStore,
  chatStore: ChatStore,
});

export type IRootStore = Instance<typeof RootStore>;

export const RootStoreContext = createContext<IRootStore | null>(null);

export const RootStoreProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const store = useLocalObservable(() =>
    RootStore.create({
      homePageStore: {
        click: false,
      },
      chatStore: {
        click: false,
        messages: [],
        employerTemplatesMocData: [],
        templateTalentMocData: [],
      },
    })
  );
  makeInspectable(store);
  return <RootStoreContext.Provider value={store}>{children}</RootStoreContext.Provider>;
};
