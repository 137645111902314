import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './createProfile.module.scss';
import ProgressBar from '../../../components/Talent/ProgressBar';
import titleBg from '../../../img/Ctrate-form/title_bg.svg';
import arrow from '../../../img/Ctrate-form/arrow-down.svg';
import PopUpCreateProfile from '../../../components/Talent/PopUpCreateProfile';
import PopUpVerifyPhone from '../../../components/Talent/PopUpVerifyPhone';
import CreateProfileNavigation from '../../../components/Talent/CreateProfileNavigation/CreateProfileNavigation';

interface FormInputs {
  name: string;
  citizenship: string;
  profession: string;
  phoneNumber: string;
  // email: string;
  termsAccepted: boolean;
}

const CreateProfile: React.FC = () => {
  const { t } = useTranslation('common');
  // Open/close popUp
  const [isCitizenshipPopupOpen, setIsCitizenshipPopupOpen] = useState(false);
  const [isProfessionPopupOpen, setIsProfessionPopupOpen] = useState(false);
  const [isVerifyPhonePopupOpen, setIsVerifyPhonePopupOpen] = useState(false);

  // Select object
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedProfession, setSelectedProfession] = useState('');
  const [selectedPhone, setSelectedPhone] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormInputs>();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    console.log(data);
    navigate('/talent/welcome-to-perfomx');
  };

  const handleCitizenshipClick = () => {
    setIsCitizenshipPopupOpen(true);
  };

  const handleProfessionClick = () => {
    setIsProfessionPopupOpen(true);
  };

  const handleVerifyPhoneClick = () => {
    setIsVerifyPhonePopupOpen(true);
  };

  const countries = [
    t('createProfile1.country1'),
    t('createProfile1.country2'),
    t('createProfile1.country3'),
    t('createProfile1.country4'),
    t('createProfile1.country5'),
    t('createProfile1.country6'),
    t('createProfile1.country7'),
  ];
  const profession = [
    t('createProfile1.profession1'),
    t('createProfile1.profession2'),
    t('createProfile1.profession3'),
    t('createProfile1.profession4'),
    t('createProfile1.profession5'),
    t('createProfile1.profession6'),
    t('createProfile1.profession7'),
    t('createProfile1.profession8'),
    t('createProfile1.profession9'),
    t('createProfile1.profession10'),
    t('createProfile1.profession11'),
    t('createProfile1.profession12'),
    t('createProfile1.profession13'),
    t('createProfile1.profession14'),
    t('createProfile1.profession15'),
    t('createProfile1.profession16'),
  ];

  return (
    <section className={styles.createProfile}>
      <ProgressBar activeSteps={1} formSteps={5} />
      <div className={styles.titleBox}>
        <img className={styles.img} width={231} height={77} src={titleBg} alt='Create profile' />
        <h1 className={styles.title}>{t('createProfile1.title')}</h1>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <input
          className={styles.createProfileInput}
          placeholder={t('createProfile1.name')}
          {...register('name', { required: t('createProfile1.requiredName') })}
        />
        {errors.name && <p className={styles.errorMessage}>{errors.name.message}</p>}

        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder={t('createProfile1.citizenship')}
            value={selectedCountry}
            onClick={handleCitizenshipClick}
            readOnly
            {...register('citizenship', { required: t('createProfile1.requiredCitizenship') })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.citizenship && <p className={styles.errorMessage}>{errors.citizenship.message}</p>}

        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder={t('createProfile1.profession')}
            value={selectedProfession}
            onClick={handleProfessionClick}
            readOnly
            {...register('profession', { required: t('createProfile1.requiredProfession') })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.profession && <p className={styles.errorMessage}>{errors.profession.message}</p>}

        <input
          className={styles.createProfileInput}
          type='number'
          placeholder={t('createProfile1.phoneNumber')}
          onClick={handleVerifyPhoneClick}
          {...register('phoneNumber', { required: t('createProfile1.requiredPhoneNumber') })}
        />
        {/*{errors.phoneNumber && <p className={styles.errorMessage}>{errors.phoneNumber.message}</p>}*/}

        {/*<input
          type='email'
          className={styles.createProfileInput}
          placeholder='e-mail'
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Invalid email address',
            },
          })}
        />
        {errors.email && <p className={styles.errorMessage}>{errors.email.message}</p>}*/}
        <p className={styles.createProfileDesc}>{t('createProfile1.note')}</p>
        <aside className={styles.termsAcceptedContainer}>
          <label className={styles.checkBox}>
            <input type='checkbox' {...register('termsAccepted', { required: t('createProfile1.requiredTerms') })} />
            <span className={styles.createProfileDesc}>
              {t('createProfile1.privacyPolicy1')}{' '}
              <Link className={styles.privacyLink} to='/'>
                {t('createProfile1.privacyPolicy2')}
              </Link>{' '}
              {t('createProfile1.privacyPolicy3')}{' '}
              <Link className={styles.privacyLink} to='/'>
                {t('createProfile1.privacyPolicy4')}{' '}
              </Link>
              {t('createProfile1.privacyPolicy5')}{' '}
              <Link className={styles.privacyLink} to='/'>
                {t('createProfile1.privacyPolicy6')}
              </Link>
            </span>
          </label>
          {errors.termsAccepted && <p className={styles.termsAccepted}>{errors.termsAccepted.message}</p>}
        </aside>

        <CreateProfileNavigation backLink='' />
      </form>

      <PopUpCreateProfile
        title={t('createProfile1.citizenship')}
        popupOpen={isCitizenshipPopupOpen}
        setPopupOpen={setIsCitizenshipPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountry(value);
          setValue('citizenship', value);
        }}
        selectedObject={selectedCountry}
        itemArray={countries}
      />
      <PopUpCreateProfile
        title={t('createProfile1.profession')}
        popupOpen={isProfessionPopupOpen}
        setPopupOpen={setIsProfessionPopupOpen}
        setSelectedObject={(value) => {
          setSelectedProfession(value);
          setValue('profession', value);
        }}
        selectedObject={selectedProfession}
        itemArray={profession}
      />
      <PopUpVerifyPhone
        title={t('createProfile1.popUpVerifyPhoneTitle')}
        popupOpen={isVerifyPhonePopupOpen}
        setPopupOpen={setIsVerifyPhonePopupOpen}
        setSelectedObject={(value) => {
          setSelectedPhone(value);
          setValue('phoneNumber', value);
        }}
      />
    </section>
  );
};

export default CreateProfile;
