import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input';
import PopUpCreateProfile from '../../../components/Talent/PopUpCreateProfile';
import CreateProfileNavigation from '../../../components/Talent/CreateProfileNavigation/CreateProfileNavigation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ProgressBarEmployer from '../../../components/Employer/ProgressBarEmployer';
import arrow from '../../../img/Ctrate-form/arrow-down.svg';
import calendarImg from '../../../img/Ctrate-form/calendar.svg';
import watchImg from '../../../img/Create-form-employer/watch.svg';
import saveBtn from '../../../img/Create-form-employer/save_btn.svg';
import formPostJob from '../../../img/Create-form-employer/form_post_job_2.svg';

import styles from './formPostJob.module.scss';

interface FormValues {
  age: number;
  height: number;
  weight: number;
  gender: string;
  appearance: string;
  languageSkills: string;
  calendar: string;
  countryJobPosting: string;
  expectedSalary: string;
  city: string;
  placeWork: string;
  vacancies: string;
  salaryBonuses: string;
  paymentSchedule: string;
  withoutBreak: string;
  contractDuration: string;
  workingHours: {
    from: string;
    to: string;
  };
  workingBreak: {
    from: string;
    to: string;
  };
  companyProvides: string;
  accommodation: string;
  meals: string;
  cuisine: string;
}

const FormPostJob = () => {
  const { t } = useTranslation('common');

  const countriesForWorkArray = [
    `${t('formJob.country1')}`,
    `${t('formJob.country2')}`,
    `${t('formJob.country3')}`,
    `${t('formJob.country4')}`,
    `${t('formJob.country5')}`,
    `${t('formJob.country6')}`,
    `${t('formJob.country7')}`,
  ];

  const cities = [
    `${t('formJob.city1')}`,
    `${t('formJob.city2')}`,
    `${t('formJob.city3')}`,
    `${t('formJob.city4')}`,
    `${t('formJob.city5')}`,
    `${t('formJob.city6')}`,
    `${t('formJob.city7')}`,
    `${t('formJob.city8')}`,
  ];

  const placeWork = [
    `${t('formJob.placeWork1')}`,
    `${t('formJob.placeWork2')}`,
    `${t('formJob.placeWork3')}`,
    `${t('formJob.placeWork4')}`,
    `${t('formJob.placeWork5')}`,
    `${t('formJob.placeWork6')}`,
  ];

  const vacanciesArray = [
    `${t('formJob.vacancy1')}`,
    `${t('formJob.vacancy2')}`,
    `${t('formJob.vacancy3')}`,
    `${t('formJob.vacancy4')}`,
    `${t('formJob.vacancy5')}`,
    `${t('formJob.vacancy6')}`,
    `${t('formJob.vacancy7')}`,
    `${t('formJob.vacancy8')}`,
    `${t('formJob.vacancy9')}`,
  ];

  const regions = [
    `${t('formJob.appearance1')}`,
    `${t('formJob.appearance2')}`,
    `${t('formJob.appearance3')}`,
    `${t('formJob.appearance4')}`,
    `${t('formJob.appearance5')}`,
  ];

  const languages = [`${t('formJob.language1')}`, `${t('formJob.language2')}`, `${t('formJob.language3')}`];

  const expectedSalaryArray = [
    `${t('formJob.salary1')}`,
    `${t('formJob.salary2')}`,
    `${t('formJob.salary3')}`,
    `${t('formJob.salary4')}`,
    `${t('formJob.salary5')}`,
    `${t('formJob.salary6')}`,
  ];

  const mealsArray = [
    `${t('formJob.nutrition1')}`,
    `${t('formJob.nutrition2')}`,
    `${t('formJob.nutrition3')}`,
    `${t('formJob.nutrition4')}`,
  ];

  const contractDurationArray = [
    `${t('formJob.duration1')}`,
    `${t('formJob.duration2')}`,
    `${t('formJob.duration3')}`,
    `${t('formJob.duration4')}`,
  ];

  const companyProvidesArray = [
    `${t('formJob.companyProvides1')}`,
    `${t('formJob.companyProvides2')}`,
    `${t('formJob.companyProvides3')}`,
    `${t('formJob.companyProvides4')}`,
    `${t('formJob.companyProvides5')}`,
    `${t('formJob.companyProvides6')}`,
    `${t('formJob.companyProvides7')}`,
  ];

  const accommodationArray = [
    `${t('formJob.accommodation1')}`,
    `${t('formJob.accommodation2')}`,
    `${t('formJob.accommodation3')}`,
    `${t('formJob.accommodation4')}`,
  ];

  const paymentScheduleArray = [
    `${t('formJob.payment1')}`,
    `${t('formJob.payment2')}`,
    `${t('formJob.payment3')}`,
    `${t('formJob.payment4')}`,
    `${t('formJob.payment5')}`,
  ];

  const cuisineArray = [`${t('formJob.cuisine1')}`, `${t('formJob.cuisine2')}`, `${t('formJob.cuisine3')}`];

  const [withoutBreakActive, setWithoutBreakActive] = useState(true);

  const [genderActive, setGenderActive] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [isAppearancePopupOpen, setIsAppearancePopupOpen] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState('');
  const [isLanguagePopupOpen, setIsLanguagePopupOpen] = React.useState(false);

  const [isCountryPopupOpen, setIsCountryPopupOpen] = React.useState(false);
  const [selectedCountryForWork, setSelectedCountryForWork] = React.useState('');

  const [isCityPopupOpen, setIsCityPopupOpen] = React.useState(false);
  const [selectedCity, setSelectedCity] = React.useState('');

  const [isExpectedSalaryPopupOpen, setIsExpectedSalaryPopupOpen] = React.useState(false);
  const [selectedExpectedSalary, setSelectedExpectedSalary] = React.useState('');

  const [isPlaceWorkPopupOpen, setIsPlaceWorkPopupOpen] = React.useState(false);
  const [selectedPlaceWork, setSelectedPlaceWork] = React.useState('');

  const [isVacanciesPopupOpen, setIsVacanciesPopupOpen] = React.useState(false);
  const [selectedVacancies, setSelectedVacancies] = React.useState('');

  const [isPaymentSchedulePopupOpen, setIsPaymentSchedulePopupOpen] = React.useState(false);
  const [selectedPaymentSchedule, setSelectedPaymentSchedule] = React.useState('');

  const [isContractDurationPopupOpen, setIsContractDurationPopupOpen] = React.useState(false);
  const [selectedContractDuration, setSelectedContractDuration] = React.useState('');

  const [isCompanyProvidesPopupOpen, setIsCompanyProvidesPopupOpen] = React.useState(false);
  const [selectedCompanyProvides, setSelectedCompanyProvides] = React.useState('');

  const [isAccommodationPopupOpen, setIsAccommodationPopupOpen] = React.useState(false);
  const [selectedAccommodation, setSelectedAccommodation] = React.useState('');

  const [isMealsPopupOpen, setIsMealsPopupOpen] = React.useState(false);
  const [selectedMeals, setSelectedMeals] = React.useState('');

  const [isCuisinePopupOpen, setIsCuisinePopupOpen] = React.useState(false);
  const [selectedCuisine, setSelectedCuisine] = React.useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: {
      age: 25,
      height: 165,
      weight: 55,
      gender: '',
      appearance: '',
      languageSkills: '',
      calendar: '',
      countryJobPosting: '',
      expectedSalary: '',
      city: '',
      placeWork: '',
      vacancies: '',
      salaryBonuses: '',
      paymentSchedule: '',
      withoutBreak: '',
      contractDuration: '',
      workingHours: { from: '', to: '' },
      workingBreak: { from: '', to: '' },
      companyProvides: '',
      accommodation: '',
      meals: '',
      cuisine: '',
    },
  });

  const age = watch('age');
  const height = watch('height');
  const weight = watch('weight');

  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setValue('calendar', date?.toISOString() || '');
    if (date) {
      clearErrors('calendar');
    }
  };

  const handleGenderClick = (gender: string) => {
    setGenderActive(gender);
    setValue('gender', gender);
  };

  const [isTimePickerOpen, setIsTimePickerOpen] = useState<null | 'from' | 'to'>(null);
  const [workingHours, setWorkingHours] = useState<{ from: string; to: string }>({
    from: '',
    to: '',
  });

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const handleTimeChange = (field: 'from' | 'to', hours: number, minutes: number) => {
    const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    setWorkingHours((prev) => ({ ...prev, [field]: time }));
    setValue(`workingHours.${field}`, time);
    setIsTimePickerOpen(null);
  };

  const [isWorkingBreakOpen, setIsWorkingBreakOpen] = useState<null | 'from' | 'to'>(null);
  const [workingBreak, setWorkingBreak] = useState<{ from: string; to: string }>({
    from: '',
    to: '',
  });

  const [hoursWorkingBreak, setHoursWorkingBreak] = useState(0);
  const [minutesWorkingBreak, setMinutesWorkingBreak] = useState(0);

  const handleTimeChangeWorkingBreak = (
    field: 'from' | 'to',
    hoursWorkingBreak: number,
    minutesWorkingBreak: number
  ) => {
    const time = `${hoursWorkingBreak.toString().padStart(2, '0')}:${minutesWorkingBreak.toString().padStart(2, '0')}`;
    setWorkingBreak((prev) => ({ ...prev, [field]: time }));
    setValue(`workingBreak.${field}`, time);
    setIsWorkingBreakOpen(null);
  };

  const onSubmit = (data: FormValues) => {
    if (!selectedDate) {
      setError('calendar', { type: 'manual', message: t('formJob.calendarErrors') });
      return;
    }
    navigate('/employer/create-profile-3');
    console.log('Form data:', data);
  };

  return (
    <section className={styles.formPostJob}>
      <ProgressBarEmployer activeSteps={2} formSteps={5} />
      <div className={styles.titleBox}>
        <img className={styles.img} width={221} height={74} src={formPostJob} alt='Form post job' />
        <h1 className={styles.titlePage}>{t('formJob.title')}</h1>
      </div>
      <p className={styles.subTitle}>{t('formJob.subTitle')}</p>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          errors={errors}
          value={selectedCountryForWork}
          popUpOpen={() => setIsCountryPopupOpen(true)}
          register={register}
          fieldName='countryJobPosting'
          placeholder={t('formJob.countryPlaceholder')}
          errorMassage={t('formJob.countryError')}
        />
        <Input
          errors={errors}
          value={selectedCity}
          popUpOpen={() => setIsCityPopupOpen(true)}
          register={register}
          fieldName='city'
          placeholder={t('formJob.cityPlaceholder')}
          errorMassage={t('formJob.cityError')}
        />
        <Input
          errors={errors}
          value={selectedPlaceWork}
          popUpOpen={() => setIsPlaceWorkPopupOpen(true)}
          register={register}
          fieldName='placeWork'
          placeholder={t('formJob.placeWorkPlaceholder')}
          errorMassage={t('formJob.placeWorkError')}
        />
        <Input
          errors={errors}
          value={selectedVacancies}
          popUpOpen={() => setIsVacanciesPopupOpen(true)}
          register={register}
          fieldName='vacancies'
          placeholder={t('formJob.vacanciesPlaceholder')}
          errorMassage={t('formJob.vacanciesError')}
        />
        <h3 className={styles.title}>{t('formJob.candidate')}</h3>
        <h3 className={styles.title}>
          <span>{t('formJob.gender')}</span>
        </h3>
        <article className={styles.genderBtnBox}>
          <label className={`${styles.genderBtn} ${genderActive === 'male' && styles.genderSelection}`}>
            <input
              className={styles.createProfileInput}
              type='radio'
              value='male'
              {...register('gender', { required: 'Gender is required' })}
              onClick={() => handleGenderClick('male')}
              checked={genderActive === 'male'}
              hidden
            />
            {t('formJob.male')}
          </label>

          <label className={`${styles.genderBtn} ${genderActive === 'female' && styles.genderSelection}`}>
            <input
              className={styles.createProfileInput}
              type='radio'
              value='female'
              {...register('gender', { required: t('formJob.genderError') })}
              onClick={() => handleGenderClick('female')}
              checked={genderActive === 'female'}
              hidden
            />
            {t('formJob.female')}
          </label>
        </article>
        <article className={styles.parameters}>
          {errors.gender && <p className={styles.errorMessage}>{errors.gender.message}</p>}
          <label>
            <span className={styles.parametersBox}>
              <span>{t('formJob.age')}</span>
              <span>{age}</span>
            </span>
            <input
              className={styles.inputRange}
              type='range'
              min='1'
              max='100'
              {...register('age', { required: true, min: 1 })}
            />
          </label>
          {/*{errors.age && <p className={styles.errorMessage}>Age is required and must be at least 1.</p>}*/}
          <label>
            <span className={styles.parametersBox}>
              <span>{t('formJob.height')}</span>
              <span>{height}</span>
            </span>
            <input
              className={styles.inputRange}
              type='range'
              min='1'
              max='200'
              {...register('height', { required: true })}
            />
          </label>
          <label>
            <span className={styles.parametersBox}>
              <span>{t('formJob.weight')}</span>
              <span>{weight}</span>
            </span>
            <input
              className={styles.inputRange}
              type='range'
              min='1'
              max='150'
              {...register('weight', { required: true })}
            />
          </label>
        </article>

        <Input
          errors={errors}
          value={selectedCountry}
          popUpOpen={() => setIsAppearancePopupOpen(true)}
          register={register}
          fieldName='appearance'
          placeholder={t('formJob.appearancePlaceholder')}
          errorMassage={t('formJob.appearanceError')}
        />

        <Input
          errors={errors}
          value={selectedLanguage}
          popUpOpen={() => setIsLanguagePopupOpen(true)}
          register={register}
          fieldName='languageSkills'
          placeholder={t('formJob.languageSkillsPlaceholder')}
          errorMassage={t('formJob.languageSkillsErrors')}
        />

        <h3 className={styles.title}>{t('formJob.terms')}</h3>
        <h3 className={styles.title}>
          <span>{t('formJob.salary')}</span>
        </h3>

        <Input
          errors={errors}
          value={selectedExpectedSalary}
          popUpOpen={() => setIsExpectedSalaryPopupOpen(true)}
          register={register}
          fieldName='expectedSalary'
          placeholder={t('formJob.expectedSalaryPlaceholder')}
          errorMassage={t('formJob.expectedSalaryErrors')}
        />

        <textarea
          className={styles.workExperience}
          placeholder={t('formJob.salaryBonusesPlaceholder')}
          {...register('salaryBonuses', { required: t('formJob.salaryBonusesErrors') })}
        />
        {errors.salaryBonuses && <p className={styles.errorMessage}>{errors.salaryBonuses.message}</p>}

        <Input
          errors={errors}
          value={selectedPaymentSchedule}
          popUpOpen={() => setIsPaymentSchedulePopupOpen(true)}
          register={register}
          fieldName='paymentSchedule'
          placeholder={t('formJob.paymentSchedulePlaceholder')}
          errorMassage={t('formJob.paymentScheduleErrors')}
        />

        <h3 className={styles.title}>
          <span>{t('formJob.working')}</span>
        </h3>
        <div className={styles.workingContainer}>
          <div className={styles.workingBox}>
            <label className={styles.labelBox}>
              <input
                className={styles.createProfileInput}
                type='text'
                placeholder={t('formJob.fromPlaceholder')}
                value={workingHours.from}
                onClick={() => setIsTimePickerOpen('from')}
                readOnly
                {...register('workingHours.from', { required: t('formJob.fromError') })}
              />
              <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
              {errors?.workingHours?.from && <p className={styles.errorMessage}>{errors.workingHours.from.message}</p>}
            </label>

            <label className={styles.labelBox}>
              <input
                className={styles.createProfileInput}
                type='text'
                placeholder={t('formJob.toPlaceholder')}
                value={workingHours.to}
                onClick={() => setIsTimePickerOpen('to')}
                readOnly
                {...register('workingHours.to', { required: t('formJob.toErrors') })}
              />
              <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
              {errors?.workingHours?.to && <p className={styles.errorMessage}>{errors.workingHours.to.message}</p>}
            </label>
          </div>

          {isTimePickerOpen && (
            <article className={styles.timePickerPopup}>
              <div className={styles.timePickerBox}>
                <img width={18} height={18} src={watchImg} alt='watch' />
                <p className={styles.title}>{t('formJob.time')}</p>
              </div>
              <div className={styles.timeBox}>
                <p className={styles.time}>{hours.toString().padStart(2, '0')}</p>
                <p className={styles.time}>:</p>
                <p className={styles.time}>{minutes.toString().padStart(2, '0')}</p>
              </div>
              <div className={styles.rangeContainer}>
                <div className={styles.rangeBox}>
                  <label>{t('formJob.hours')}</label>
                  <input
                    className={styles.inputRange}
                    type='range'
                    min='0'
                    max='23'
                    value={hours}
                    onChange={(e) => setHours(Number(e.target.value))}
                  />
                </div>
                <div className={styles.rangeBox}>
                  <label>{t('formJob.minutes')}</label>
                  <input
                    className={styles.inputRange}
                    type='range'
                    min='0'
                    max='59'
                    value={minutes}
                    onChange={(e) => setMinutes(Number(e.target.value))}
                  />
                </div>
              </div>

              <button className={styles.saveBtn} onClick={() => handleTimeChange(isTimePickerOpen, hours, minutes)}>
                <img width={12} height={10} src={saveBtn} alt='save btn' />
                <span>{t('formJob.save')}</span>
              </button>
            </article>
          )}
        </div>

        {withoutBreakActive && (
          <>
            <h3 className={styles.title}>
              <span>{t('formJob.break')}</span>
            </h3>

            <div className={styles.workingContainer}>
              <div className={styles.workingBox}>
                <label className={styles.labelBox}>
                  <input
                    className={styles.createProfileInput}
                    type='text'
                    placeholder='From:'
                    value={workingBreak.from}
                    onClick={() => setIsWorkingBreakOpen('from')}
                    readOnly
                    {...register('workingBreak.from', { required: t('formJob.workingBreakErrors') })}
                  />
                  <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
                  {errors?.workingBreak?.from && (
                    <p className={styles.errorMessage}>{errors.workingBreak.from.message}</p>
                  )}
                </label>

                <label className={styles.labelBox}>
                  <input
                    className={styles.createProfileInput}
                    type='text'
                    placeholder={t('formJob.toPlaceholder')}
                    value={workingBreak.to}
                    onClick={() => setIsWorkingBreakOpen('to')}
                    readOnly
                    {...register('workingBreak.to', { required: t('formJob.toErrors') })}
                  />
                  <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
                  {errors?.workingBreak?.to && <p className={styles.errorMessage}>{errors.workingBreak.to.message}</p>}
                </label>
              </div>

              {isWorkingBreakOpen && (
                <article className={styles.timePickerPopup}>
                  <div className={styles.timePickerBox}>
                    <img width={18} height={18} src={watchImg} alt='watch' />
                    <p className={styles.title}>{t('formJob.time')}</p>
                  </div>
                  <div className={styles.timeBox}>
                    <p className={styles.time}>{hoursWorkingBreak.toString().padStart(2, '0')}</p>
                    <p className={styles.time}>:</p>
                    <p className={styles.time}>{minutesWorkingBreak.toString().padStart(2, '0')}</p>
                  </div>
                  <div className={styles.rangeContainer}>
                    <div className={styles.rangeBox}>
                      <label>Hours:</label>
                      <input
                        className={styles.inputRange}
                        type='range'
                        min='0'
                        max='23'
                        value={hoursWorkingBreak}
                        onChange={(e) => setHoursWorkingBreak(Number(e.target.value))}
                      />
                    </div>
                    <div className={styles.rangeBox}>
                      <label>Minutes:</label>
                      <input
                        className={styles.inputRange}
                        type='range'
                        min='0'
                        max='59'
                        value={minutesWorkingBreak}
                        onChange={(e) => setMinutesWorkingBreak(Number(e.target.value))}
                      />
                    </div>
                  </div>

                  <button
                    className={styles.saveBtn}
                    onClick={() =>
                      handleTimeChangeWorkingBreak(isWorkingBreakOpen, hoursWorkingBreak, minutesWorkingBreak)
                    }>
                    <img width={12} height={10} src={saveBtn} alt='save btn' />
                    <span>{t('formJob.save')}</span>
                  </button>
                </article>
              )}
            </div>
          </>
        )}

        <label className={styles.checkBox}>
          <input
            type='checkbox'
            {...register('withoutBreak')}
            onClick={() => setWithoutBreakActive(!withoutBreakActive)}
          />
          <span className={styles.desc}>{t('formJob.withoutBreak')}</span>
        </label>

        <label className={styles.calendarBox}>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat='dd/MM/yyyy'
            className={styles.createProfileInput}
            placeholderText={t('formJob.calendarPlaceholder')}
          />
          <img className={styles.calendarImg} width={30} height={30} src={calendarImg} alt='calendar' />
        </label>
        {errors.calendar && <p className={styles.errorMessage}>{errors.calendar.message}</p>}

        <Input
          errors={errors}
          value={selectedContractDuration}
          popUpOpen={() => setIsContractDurationPopupOpen(true)}
          register={register}
          fieldName='contractDuration'
          placeholder={t('formJob.contractDurationPlaceholder')}
          errorMassage={t('formJob.contractDurationErrors')}
        />

        <h3 className={styles.title}>{t('formJob.conditions')}</h3>
        <Input
          errors={errors}
          value={selectedCompanyProvides}
          popUpOpen={() => setIsCompanyProvidesPopupOpen(true)}
          register={register}
          fieldName='companyProvides'
          placeholder={t('formJob.contractDurationPlaceholder')}
          errorMassage={t('formJob.contractDurationErrors')}
        />

        <Input
          errors={errors}
          value={selectedAccommodation}
          popUpOpen={() => setIsAccommodationPopupOpen(true)}
          register={register}
          fieldName='accommodation'
          placeholder={t('formJob.accommodationPlaceholder')}
          errorMassage={t('formJob.accommodationErrors')}
        />

        <Input
          errors={errors}
          value={selectedMeals}
          popUpOpen={() => setIsMealsPopupOpen(true)}
          register={register}
          fieldName='meals'
          placeholder={t('formJob.mealsPlaceholder')}
          errorMassage={t('formJob.mealsErrors')}
        />

        <Input
          errors={errors}
          value={selectedCuisine}
          popUpOpen={() => setIsCuisinePopupOpen(true)}
          register={register}
          fieldName='cuisine'
          placeholder={t('formJob.cuisinePlaceholder')}
          errorMassage={t('formJob.cuisineErrors')}
        />

        <CreateProfileNavigation backLink='/employer/create-profile-1' />
      </form>
      <PopUpCreateProfile
        title={t('formJob.countryPopUpTitle')}
        popupOpen={isCountryPopupOpen}
        setPopupOpen={setIsCountryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountryForWork(value);
          setValue('countryJobPosting', value);
        }}
        selectedObject={selectedCountryForWork}
        itemArray={countriesForWorkArray}
      />

      <PopUpCreateProfile
        title={t('formJob.cityPopUpTitle')}
        popupOpen={isCityPopupOpen}
        setPopupOpen={setIsCityPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCity(value);
          setValue('city', value);
        }}
        selectedObject={selectedCity}
        itemArray={cities}
      />

      <PopUpCreateProfile
        title={t('formJob.placeWorkPopUpTitle')}
        popupOpen={isPlaceWorkPopupOpen}
        setPopupOpen={setIsPlaceWorkPopupOpen}
        setSelectedObject={(value) => {
          setSelectedPlaceWork(value);
          setValue('placeWork', value);
        }}
        selectedObject={selectedPlaceWork}
        itemArray={placeWork}
      />

      <PopUpCreateProfile
        title={t('formJob.schedulePopUpTitle')}
        popupOpen={isPaymentSchedulePopupOpen}
        setPopupOpen={setIsPaymentSchedulePopupOpen}
        setSelectedObject={(value) => {
          setSelectedPaymentSchedule(value);
          setValue('paymentSchedule', value);
        }}
        selectedObject={selectedPaymentSchedule}
        itemArray={paymentScheduleArray}
      />

      <PopUpCreateProfile
        title={t('formJob.vacanciesPopUpTitle')}
        popupOpen={isVacanciesPopupOpen}
        setPopupOpen={setIsVacanciesPopupOpen}
        setSelectedObject={(value) => {
          setSelectedVacancies(value);
          setValue('vacancies', value);
        }}
        selectedObject={selectedVacancies}
        itemArray={vacanciesArray}
      />

      <PopUpCreateProfile
        title={t('formJob.appearancePopUpTitle')}
        popupOpen={isAppearancePopupOpen}
        setPopupOpen={setIsAppearancePopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountry(value);
          setValue('appearance', value);
        }}
        selectedObject={selectedCountry}
        itemArray={regions}
      />
      <PopUpCreateProfile
        title={t('formJob.languagePopUpTitle')}
        popupOpen={isLanguagePopupOpen}
        setPopupOpen={setIsLanguagePopupOpen}
        setSelectedObject={(value) => {
          setSelectedLanguage(value);
          setValue('languageSkills', value);
        }}
        selectedObject={selectedLanguage}
        itemArray={languages}
      />

      <PopUpCreateProfile
        title={t('formJob.salaryPopUpTitle')}
        popupOpen={isExpectedSalaryPopupOpen}
        setPopupOpen={setIsExpectedSalaryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedExpectedSalary(value);
          setValue('expectedSalary', value);
        }}
        selectedObject={selectedExpectedSalary}
        itemArray={expectedSalaryArray}
      />
      <PopUpCreateProfile
        title={t('formJob.contractDurationPopUpTitle')}
        popupOpen={isContractDurationPopupOpen}
        setPopupOpen={setIsContractDurationPopupOpen}
        setSelectedObject={(value) => {
          setSelectedContractDuration(value);
          setValue('contractDuration', value);
        }}
        selectedObject={selectedContractDuration}
        itemArray={contractDurationArray}
      />
      <PopUpCreateProfile
        title={t('formJob.companyProvidesPopUpTitle')}
        popupOpen={isCompanyProvidesPopupOpen}
        setPopupOpen={setIsCompanyProvidesPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCompanyProvides(value);
          setValue('companyProvides', value);
        }}
        selectedObject={selectedCompanyProvides}
        itemArray={companyProvidesArray}
      />
      <PopUpCreateProfile
        title={t('formJob.accommodationPopUpTitle')}
        popupOpen={isAccommodationPopupOpen}
        setPopupOpen={setIsAccommodationPopupOpen}
        setSelectedObject={(value) => {
          setSelectedAccommodation(value);
          setValue('accommodation', value);
        }}
        selectedObject={selectedAccommodation}
        itemArray={accommodationArray}
      />

      <PopUpCreateProfile
        title={t('formJob.mealsPopUpTitle')}
        popupOpen={isMealsPopupOpen}
        setPopupOpen={setIsMealsPopupOpen}
        setSelectedObject={(value) => {
          setSelectedMeals(value);
          setValue('meals', value);
        }}
        selectedObject={selectedMeals}
        itemArray={mealsArray}
      />

      <PopUpCreateProfile
        title={t('formJob.cuisinePopUpTitle')}
        popupOpen={isCuisinePopupOpen}
        setPopupOpen={setIsCuisinePopupOpen}
        setSelectedObject={(value) => {
          setSelectedCuisine(value);
          setValue('cuisine', value);
        }}
        selectedObject={selectedCuisine}
        itemArray={cuisineArray}
      />
    </section>
  );
};

export default FormPostJob;
