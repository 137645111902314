import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FieldError } from 'react-hook-form';
import PopUpCreateProfile from '../../../components/Talent/PopUpCreateProfile';
import CreateProfileNavigation from '../../../components/Talent/CreateProfileNavigation/CreateProfileNavigation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ProgressBar from '../../../components/Talent/ProgressBar';
import jobApplicationBg from "../../../img/Ctrate-form/job_application_bg.svg";
import arrow from '../../../img/Ctrate-form/arrow-down.svg';
import calendarImg from '../../../img/Ctrate-form/calendar.svg';
import { useTranslation } from 'react-i18next';

import styles from './createProfileJobApplication.module.scss';

interface FormValues {
  age: number;
  height: number;
  weight: number;
  gender: string;
  appearance: string;
  languageSkills: string;
  workExperience: string;
  aboutYourself: string;
  location: string;
  calendar: string;
  contractCountry: string;
  contractPeriod: string;
  expectedSalary: string;
}

const CreateProfileJobApplication = () => {
  const { t } = useTranslation('common');
  const countries = [
    `${t('jobApplication.country1')}`,
    `${t('jobApplication.country2')}`,
    `${t('jobApplication.country3')}`,
    `${t('jobApplication.country4')}`,
    `${t('jobApplication.country5')}`,
  ];

  const languages = [
    `${t('jobApplication.language1')}`,
    `${t('jobApplication.language2')}`,
    `${t('jobApplication.language3')}`,
  ];

  const countriesForWorkArray = [
    `${t('jobApplication.countriesForWorCountry1')}`,
    `${t('jobApplication.countriesForWorCountry2')}`,
    `${t('jobApplication.countriesForWorCountry3')}`,
    `${t('jobApplication.countriesForWorCountry4')}`,
    `${t('jobApplication.countriesForWorCountry5')}`,
    `${t('jobApplication.countriesForWorCountry6')}`,
    `${t('jobApplication.countriesForWorCountry7')}`,
  ];

  const contractPeriodArray = [
    `${t('jobApplication.contractPeriodItem1')}`,
    `${t('jobApplication.contractPeriodItem2')}`,
    `${t('jobApplication.contractPeriodItem3')}`,
    `${t('jobApplication.contractPeriodItem4')}`,
  ];

  const expectedSalaryArray = [
    `${t('jobApplication.salaryItem1')}`,
    `${t('jobApplication.salaryItem2')}`,
    `${t('jobApplication.salaryItem3')}`,
    `${t('jobApplication.salaryItem4')}`,
    `${t('jobApplication.salaryItem5')}`,
    `${t('jobApplication.salaryItem6')}`,
  ];

  const [genderActive, setGenderActive] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [isAppearancePopupOpen, setIsAppearancePopupOpen] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState('');
  const [isLanguagePopupOpen, setIsLanguagePopupOpen] = React.useState(false);

  const [isCountryPopupOpen, setIsCountryPopupOpen] = React.useState(false);
  const [selectedCountryForWork, setSelectedCountryForWork] = React.useState('');

  const [isContractPeriodPopupOpen, setIsContractPeriodPopupOpen] = React.useState(false);
  const [selectedContractPeriod, setSelectedContractPeriod] = React.useState('');

  const [isExpectedSalaryPopupOpen, setIsExpectedSalaryPopupOpen] = React.useState(false);
  const [selectedExpectedSalary, setSelectedExpectedSalary] = React.useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: {
      age: 25,
      height: 165,
      weight: 55,
      gender: '',
      appearance: '',
      languageSkills: '',
      workExperience: '',
      aboutYourself: '',
      location: '',
      calendar: '',
      contractCountry: '',
      contractPeriod: '',
      expectedSalary: '',
    },
  });

  const age = watch('age');
  const height = watch('height');
  const weight = watch('weight');

  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setValue('calendar', date?.toISOString() || '');
    if (date) {
      clearErrors('calendar');
    }
  };

  const onSubmit = (data: FormValues) => {
    if (!selectedDate) {
      setError('calendar', { type: 'manual', message: t('jobApplication.requiredSelectDate') });
      return;
    }
    navigate('/talent/create-profile-3');
    console.log('Form data:', data);
  };

  const handleGenderClick = (gender: string) => {
    setGenderActive(gender);
    setValue('gender', gender);
  };

  return (
    <section className={styles.createProfileJobApplication}>
      <ProgressBar activeSteps={2} formSteps={5} />
      <div className={styles.titleBox}>
        <img className={styles.img} width={243} height={79} src={jobApplicationBg} alt='background' />
        <h1 className={styles.titlePage}>{t('jobApplication.title')}</h1>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <h3 className={styles.title}>{t('jobApplication.gender')}</h3>
        <aside className={styles.genderBtnBox}>
          <label className={`${styles.genderBtn} ${genderActive === 'male' && styles.genderSelection}`}>
            <input
              className={styles.createProfileInput}
              type='radio'
              value='male'
              {...register('gender', { required: 'Gender is required' })}
              onClick={() => handleGenderClick('male')}
              checked={genderActive === 'male'}
              hidden
            />
            {t('jobApplication.male')}
          </label>

          <label className={`${styles.genderBtn} ${genderActive === 'female' && styles.genderSelection}`}>
            <input
              className={styles.createProfileInput}
              // placeholder='Female'
              type='radio'
              value='female'
              {...register('gender', { required: 'Gender is required' })}
              onClick={() => handleGenderClick('female')}
              checked={genderActive === 'female'}
              hidden
            />
            {t('jobApplication.female')}
          </label>
        </aside>
        {errors.gender && <p className={styles.errorMessage}>{errors.gender.message}</p>}
        <label>
          <span className={styles.parametersBox}>
            <span>{t('jobApplication.age')}</span>
            <span>{age}</span>
          </span>
          <input
            className={styles.inputRange}
            type='range'
            min='18'
            max='100'
            {...register('age', { required: true, min: 18 })}
          />
        </label>
        {/*{errors.age && <p className={styles.errorMessage}>Age is required and must be at least 18.</p>}*/}

        <label>
          <span className={styles.parametersBox}>
            <span>{t('jobApplication.height')}</span>
            <span>{height}</span>
          </span>
          <input
            className={styles.inputRange}
            type='range'
            min='150'
            max='200'
            {...register('height', { required: true })}
          />
        </label>

        <label>
          <span className={styles.parametersBox}>
            <span>{t('jobApplication.weight')}</span>
            <span>{weight}</span>
          </span>
          <input
            className={styles.inputRange}
            type='range'
            min='40'
            max='150'
            {...register('weight', { required: true })}
          />
        </label>

        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder={t('jobApplication.appearance')}
            value={selectedCountry}
            onClick={() => setIsAppearancePopupOpen(true)}
            readOnly
            {...register('appearance', { required: t('jobApplication.requiredAppearance') })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.appearance && <p className={styles.errorMessage}>{errors.appearance.message}</p>}

        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder={t('jobApplication.languageSkill')}
            value={selectedLanguage}
            onClick={() => setIsLanguagePopupOpen(true)}
            readOnly
            {...register('languageSkills', { required: t('jobApplication.requiredLanguageSkill') })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.languageSkills && <p className={styles.errorMessage}>{errors.languageSkills.message}</p>}

        <h3 className={styles.title}>{t('jobApplication.experience')}</h3>
        <textarea
          className={styles.workExperience}
          placeholder={t('jobApplication.worked')}
          {...register('workExperience', { required: t('jobApplication.requiredWorked') })}
        />
        {errors.workExperience && <p className={styles.errorMessage}>{errors.workExperience.message}</p>}
        <h3 className={styles.title}>{t('jobApplication.aboutYourselfTitle')}</h3>
        <textarea
          className={styles.aboutYourself}
          placeholder={t('jobApplication.aboutYourself')}
          {...register('aboutYourself', { required: t('jobApplication.requiredAboutYourselfTitle') })}
        />
        {errors.aboutYourself && <p className={styles.errorMessage}>{errors.aboutYourself.message}</p>}
        <h3 className={styles.title}>{t('jobApplication.details')}</h3>

        <input
          className={styles.createProfileInput}
          type='text'
          name='location'
          placeholder={t('jobApplication.location')}
          {...register('location', { required: t('jobApplication.requiredLocation') })}
        />
        {errors.location && <p className={styles.errorMessage}>{errors.location.message}</p>}
        <p className={styles.desc}>{t('jobApplication.languageIndication')}</p>
        <label className={styles.calendarBox}>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat='dd/MM/yyyy'
            className={styles.createProfileInput}
            placeholderText={t('jobApplication.startContract')}
          />
          <img className={styles.calendarImg} width={30} height={30} src={calendarImg} alt='calendar' />
        </label>
        {errors.calendar && <p className={styles.errorMessage}>{errors.calendar.message}</p>}
        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder={t('jobApplication.contractCountry')}
            value={selectedCountryForWork}
            onClick={() => setIsCountryPopupOpen(true)}
            readOnly
            {...register('contractCountry', { required: t('jobApplication.requiredContractCountry') })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.contractCountry && <p className={styles.errorMessage}>{errors.contractCountry.message}</p>}
        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder={t('jobApplication.contractPeriod')}
            value={selectedContractPeriod}
            onClick={() => setIsContractPeriodPopupOpen(true)}
            readOnly
            {...register('contractPeriod', { required: t('jobApplication.requiredContractPeriod') })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.contractPeriod && <p className={styles.errorMessage}>{errors.contractPeriod.message}</p>}
        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder={t('jobApplication.salary')}
            value={selectedExpectedSalary}
            onClick={() => setIsExpectedSalaryPopupOpen(true)}
            readOnly
            {...register('expectedSalary', { required: t('jobApplication.requiredSalary') })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.expectedSalary && <p className={styles.errorMessage}>{errors.expectedSalary.message}</p>}
        <CreateProfileNavigation backLink='/talent/welcome-to-perfomx' />
      </form>
      <PopUpCreateProfile
        title={t('jobApplication.appearance')}
        popupOpen={isAppearancePopupOpen}
        setPopupOpen={setIsAppearancePopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountry(value);
          setValue('appearance', value);
        }}
        selectedObject={selectedCountry}
        itemArray={countries}
      />
      <PopUpCreateProfile
        title={t('jobApplication.languageSkill')}
        popupOpen={isLanguagePopupOpen}
        setPopupOpen={setIsLanguagePopupOpen}
        setSelectedObject={(value) => {
          setSelectedLanguage(value);
          setValue('languageSkills', value);
        }}
        selectedObject={selectedLanguage}
        itemArray={languages}
      />
      <PopUpCreateProfile
        title={t('jobApplication.contractCountry')}
        popupOpen={isCountryPopupOpen}
        setPopupOpen={setIsCountryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountryForWork(value);
          setValue('contractCountry', value);
        }}
        selectedObject={selectedCountryForWork}
        itemArray={countriesForWorkArray}
      />
      <PopUpCreateProfile
        title={t('jobApplication.contractPeriod')}
        popupOpen={isContractPeriodPopupOpen}
        setPopupOpen={setIsContractPeriodPopupOpen}
        setSelectedObject={(value) => {
          setSelectedContractPeriod(value);
          setValue('contractPeriod', value);
        }}
        selectedObject={selectedContractPeriod}
        itemArray={contractPeriodArray}
      />
      <PopUpCreateProfile
        title={t('jobApplication.salary')}
        popupOpen={isExpectedSalaryPopupOpen}
        setPopupOpen={setIsExpectedSalaryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedExpectedSalary(value);
          setValue('expectedSalary', value);
        }}
        selectedObject={selectedExpectedSalary}
        itemArray={expectedSalaryArray}
      />
    </section>
  );
};

export default CreateProfileJobApplication;
