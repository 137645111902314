import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { observer } from 'mobx-react-lite';
// import { useStore } from '../../hooks/useRootStore';
import FooterHomePage from '../../components/FooterHomePage';
import { useTranslation } from 'react-i18next';
import LinkButton from '../../components/Talent/LinkButton';
import { vacancies } from '../Talent/Vacancies/mocDataVacancies';
import headerImage from '../../img/Home-page/header.png';
import menuBtn from '../../img/Home-page/burger_menu_btn.svg';
import alyona from '../../img/Home-page/alyona.jpg';
import vika from '../../img/Home-page/vika.jpg';
import zina from '../../img/Home-page/zina.jpg';
import star from '../../img/Home-page/slider_star.svg';
import money from '../../img/Home-page/money.svg';
import heart from '../../img/Home-page/heart.svg';
import people from '../../img/Home-page/people.svg';
import hands from '../../img/Home-page/hands.svg';
import advantageBg from '../../img/Home-page/advantage_bg.svg';
import favoritesImg from '../../img/Vacancies/favorites.svg';

import styles from './homePage.module.scss';

const HomePage: React.FC = observer(() => {
  const { t, i18n } = useTranslation('common');

  const sliderTalentArray = [
    { imgTalent: alyona, imgStar: star, top: '', name: 'Alena', profession: 'Dancer', citizenship: 'Russia' },
    {
      imgTalent: vika,
      imgStar: star,
      top: `${t('homePage.top')}`,
      name: 'Vika',
      profession: 'Photo model',
      citizenship: 'Bulgaria',
    },
    { imgTalent: zina, imgStar: star, top: '', name: 'Zina', profession: 'Image model', citizenship: 'Kazakhstan' },
    { imgTalent: alyona, imgStar: star, top: '', name: 'Alena', profession: 'Dancer', citizenship: 'Russia' },
    {
      imgTalent: vika,
      imgStar: star,
      top: `${t('homePage.top')}`,
      name: 'Vika',
      profession: 'Photo model',
      citizenship: 'Bulgaria',
    },
    { imgTalent: zina, imgStar: star, top: '', name: 'Zina', profession: 'Image model', citizenship: 'Kazakhstan' },
  ];

  const advantageArray = [
    {
      title: `${t('homePage.advantageSlider.first.title')}`,
      desc: `${t('homePage.advantageSlider.first.desc')}`,
      img: money,
      class: styles.yellow,
    },
    {
      title: `${t('homePage.advantageSlider.second.title')}`,
      desc: `${t('homePage.advantageSlider.second.desc')}`,
      img: heart,
      class: styles.lightPurple,
    },
    {
      title: `${t('homePage.advantageSlider.third.title')}`,
      desc: `${t('homePage.advantageSlider.third.desc')}`,
      img: people,
      class: styles.turquoise,
    },
    {
      title: `${t('homePage.advantageSlider.fourth.title')}`,
      desc: `${t('homePage.advantageSlider.fourth.desc')}`,
      img: hands,
      class: styles.pink,
    },
  ];

  /*const {
    homePageStore: { click, setClick },
  } = useStore();*/

  const [active, setActive] = useState('talent');

  const settings = {
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div className={styles.homePage}>
      <header className={styles.header}>
        <article>
          <Link className={styles.logoBtn} to='/'>
            <img width={140} height={26} src={headerImage} alt='Perfomx' />
            <span className={styles.subtitle}>Job in entertainment</span>
          </Link>
        </article>
        <div className={styles.languageBox}>
          <button className={styles.languageBtn} type='button' onClick={() => i18n.changeLanguage('en')}>
            EN
          </button>
          <button className={styles.languageBtn} type='button' onClick={() => i18n.changeLanguage('ru')}>
            Ру
          </button>
        </div>
        <Link to='/hot-bar'>
          <img width={32} height={32} src={menuBtn} alt='menu' />
        </Link>
      </header>
      <main className={styles.main}>
        <section className={styles.background}>
          <h2 className={styles.title}>
            {t('header.firstTitle')}
            <br />
            {t('header.second')}
            <br />
            {t('header.third')}
            <br />
            <span className={styles.selection}>{t('header.fourth')}</span>
          </h2>
        </section>
        <section className={styles.slider}>
          <article className={active === 'talent' ? styles.switchBtnBox : styles.switch}>
            <button
              className={`${styles.switchBtn} ${active === 'jobs' && styles.switchBtnColor}`}
              type='button'
              onClick={() => setActive('jobs')}>
              {t('homePage.workerBtn')}
            </button>
            <button
              className={`${styles.switchBtn} ${active === 'talent' && styles.switchBtnColor}`}
              type='button'
              onClick={() => setActive('talent')}>
              {t('homePage.employerBtn')}
            </button>
          </article>
          <article className={styles.sliderContainer}>
            {active === 'talent' ? (
              <Slider {...settings} className={styles.sliderBox}>
                {sliderTalentArray.map((item, index) => (
                  <figure className={styles.slideContainer} key={index}>
                    {item.top && <p className={styles.top}>{item.top}</p>}
                    <img className={styles.star} width={24} height={24} src={item.imgStar} alt='star' />
                    <img className={styles.sliderImg} src={item.imgTalent} width={120} height={120} alt={item.name} />
                    <div className={styles.descBox}>
                      <p className={styles.desc}>{item.name}</p>
                      <p className={styles.desc}>{item.profession}</p>
                    </div>
                    <figcaption className={styles.sliderTitle}>{item.citizenship}</figcaption>
                  </figure>
                ))}
              </Slider>
            ) : (
              <Slider {...settings} className={styles.sliderBox}>
                {vacancies.map((item, index) => (
                  <figure className={styles.slideContainer} key={index}>
                    {item.top && <p className={styles.top}>{item.top}</p>}
                    {item.favorites && (
                      <img className={styles.star} width={24} height={24} src={favoritesImg} alt='favorites' />
                    )}
                    <img
                      className={styles.sliderImg}
                      src={item.photoPlaceWork[0].url}
                      width={120}
                      height={120}
                      alt={item.workplace}
                    />
                    <aside className={styles.descBox}>
                      <p className={styles.desc}>{item.workplace}</p>
                      <p className={styles.desc}>$ {item.salary}</p>
                    </aside>
                    <figcaption className={styles.vacanciesTitle}>
                      {item.cities}, {item.countryJobPosting}
                    </figcaption>
                  </figure>
                ))}
              </Slider>
            )}
          </article>
          <p className={styles.joinDesc}>{t('homePage.joinDesc')}</p>
          <LinkButton
            title={t('homePage.joinLink')}
            link={active === 'talent' ? '/talent/create-profile-1' : '/employer/create-profile-1'}
          />
        </section>
        <section className={styles.advantage}>
          <div className={styles.titleBox}>
            <h1 className={styles.titlePage}>{t('homePage.title')}</h1>
            <img width={297} height={81} src={advantageBg} alt='advantage bg' />
          </div>
          <article className={styles.sliderContainer}>
            <Slider {...settings} className={styles.advantageSliderBox}>
              {advantageArray.map((item, index) => (
                <figure className={`${styles.advantageContainer} ${item.class}`} key={index}>
                  <figcaption className={styles.advantageTitle}>{item.title}</figcaption>
                  <p className={styles.advantageDesc}>{item.desc}</p>
                  <img className={styles.advantageSliderImg} src={item.img} width={120} height={120} alt={item.title} />
                </figure>
              ))}
            </Slider>
          </article>
        </section>
        <section className={styles.collaboration}>
          <h3 className={styles.perfomTitle}>{t('homePage.collaboration')}</h3>
          <p className={styles.perfomDesc}>{t('homePage.about')}</p>
          <p className={styles.perfomDesc}>{t('homePage.control')}</p>
          <p className={styles.perfomDesc}>{t('homePage.payment')}</p>
          <p className={styles.perfomDesc}>{t('homePage.join')}</p>
        </section>
      </main>
      <FooterHomePage footer='homePage' />
    </div>
  );
});

export default HomePage;
