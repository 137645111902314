import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import backArrow from '../../../img/Ctrate-form/back_arrow.svg';
import nextArrow from '../../../img/Ctrate-form/next_arrow.svg';

import styles from './createProfileNavigation.module.scss';

const CreateProfileNavigation = ({ backLink }) => {
  const { t } = useTranslation('common');

  return (
    <nav className={styles.nav}>
      <Link className={styles.navBox} to={backLink}>
        <img width={50} height={50} src={backArrow} alt='back arrow' />
        <span className={styles.navBtn}>{t('createProfileFooter.back')}</span>
      </Link>
      <button className={styles.navBox} type='submit'>
        <img width={50} height={50} src={nextArrow} alt='next arrow' />
        <span className={styles.navBtn}>{t('createProfileFooter.next')}</span>
      </button>
    </nav>
  );
};

export default CreateProfileNavigation;
