import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Talent/Footer';
import search from '../../../img/Vacancies/search_icon.svg';
import favoritesImg from '../../../img/Vacancies/favorites.svg';
import noFavoritesImg from '../../../img/Vacancies/no_favorites.svg';
import groupImg from '../../../img/Vacancies/group.svg';
import ok from '../../../img/Vacancies/ok.svg';
import filters from '../../../img/Vacancies/icon_filters.svg';
import cross from '../../../img/Vacancies/cross.svg';
import logo from '../../../img/Home-page/logo_black.svg';
import { vacancies } from './mocDataVacancies';

import styles from './vacancies.module.scss';
import {useTranslation} from "react-i18next";

const Vacancies = () => {
  const { t } = useTranslation('common');

  const [favoritesPopUp, setFavoritesPopUp] = useState(false);
  const [smallFilter, setSmallFilter] = useState('');

  const blockedPopUpFunction = () => {
    if (favoritesPopUp) {
      setTimeout(() => {
        setFavoritesPopUp(false);
      }, 3000);
    }
    return favoritesPopUp;
  };

  const btnFilterArray = [
    { smallFilter: 'contracts', desc: `${t('jobs.contractsBtn')}` },
    { smallFilter: 'favorites', desc: `${t('jobs.favorites')}` },
    { smallFilter: 'new', desc: `${t('jobs.new')}` },
  ];

  return (
    <div className={styles.vacancies}>
      <header className={styles.header}>
        <section>
          <article className={styles.headerBox}>
            <Link to='/hot-bar'>
              <img width={174} height={45} src={logo} alt='logo' />
            </Link>
            <Link to='/talent/filter'>
              <img src={filters} alt='filters btn' />
            </Link>
          </article>
          <article className={styles.navBox}>
            {btnFilterArray.map((item, index) => (
              <div className={`${styles.btnBox} ${smallFilter === item.smallFilter && styles.navBtnActive}`} key={index}>
                <button
                  type='button'
                  className={`${styles.filterBtn} ${smallFilter === item.smallFilter && styles.filterBtnActive}`}
                  onClick={() => setSmallFilter(item.smallFilter)}>
                  <span>{item.desc}</span>
                </button>
                {smallFilter === item.smallFilter && (
                  <button className={styles.crossBtn} type='button' onClick={() => setSmallFilter('')}>
                    <img width={14} height={14} src={cross} alt='cross.svg' />
                  </button>
                )}
              </div>
            ))}
          </article>
        </section>
        <label className={styles.searchBox}>
          <input className={styles.input} type='text' placeholder={t('jobs.search')} />
          <img className={styles.searchIcon} src={search} alt='search icon' />
        </label>
      </header>
      <main className={styles.main}>
        <h2 className={styles.title}>{vacancies.length} {t('jobs.title')}</h2>
        <ul className={styles.vacanciesWrapper}>
          {vacancies.map((item, index) => (
            <li className={styles.li} key={index}>
              <Link className={styles.linkToJobs} to={`/talent/jobs/${item.id}`}>
                <figure className={styles.vacanciesContainer}>
                  {item.top && <p className={styles.top}>{item.top}</p>}
                  {item.applicants > 0 && (
                    <div className={styles.group}>
                      <img width={16} height={16} src={groupImg} alt='group' />
                      <p className={styles.groupDesc}>{item.applicants}</p>
                    </div>
                  )}
                  <img
                    className={styles.vacanciesImg}
                    width={171}
                    height={171}
                    src={item.photoPlaceWork[0].url}
                    alt='house'
                  />
                  <aside className={styles.vacanciesDesc}>
                    <p>{item.workplace}</p>
                    <p>$ {item.salary}</p>
                  </aside>
                  <figcaption className={styles.vacanciesTitle}>
                    {item.cities}, {item.countryJobPosting}
                  </figcaption>
                </figure>
              </Link>
              <button className={styles.favoritesStarBtn} type='button' onClick={() => setFavoritesPopUp(true)}>
                <img width={24} height={24} src={item.favorites ? favoritesImg : noFavoritesImg} alt='favorites' />
              </button>
            </li>
          ))}
        </ul>
      </main>
      <Footer />
      {blockedPopUpFunction() && (
        <section className={styles.favoritesPopUp}>
          <article className={styles.favoritesPopUpContainer}>
            <img width={86} height={86} src={ok} alt='User Removed from favorites' />
            <p className={styles.favoritesPopUpDesc}>
              User <br />  Removed from favorites
            </p>
          </article>
        </section>
      )}
    </div>
  );
};

export default Vacancies;
