import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Employer/Footer';
import Input from '../../../components/Input';
import PopUpCreateProfile from '../../../components/Talent/PopUpCreateProfile';
import rulesImg from '../../../img/JobAlert/question.svg';

import styles from './jobAlertEmployer.module.scss';

interface IForm {
  jobAlertTextarea: string;
  age: string;
  height: string;
  weight: string;
  gender: string;
  vacancy: string;
  country: string;
  city: string;
}

const JobAlertEmployer = () => {
  const { t } = useTranslation('common');

  const vacancyArray = [
    `${t('jobAlertEmployers.vacancy1')}`,
    `${t('jobAlertEmployers.vacancy2')}`,
    `${t('jobAlertEmployers.vacancy3')}`,
    `${t('jobAlertEmployers.vacancy4')}`,
    `${t('jobAlertEmployers.vacancy5')}`,
    `${t('jobAlertEmployers.vacancy6')}`,
    `${t('jobAlertEmployers.vacancy7')}`,
    `${t('jobAlertEmployers.vacancy8')}`,
    `${t('jobAlertEmployers.vacancy9')}`,
  ];

  const countryArray = [
    `${t('jobAlertEmployers.country1')}`,
    `${t('jobAlertEmployers.country2')}`,
    `${t('jobAlertEmployers.country3')}`,
    `${t('jobAlertEmployers.country4')}`,
    `${t('jobAlertEmployers.country5')}`,
    `${t('jobAlertEmployers.country6')}`,
    `${t('jobAlertEmployers.country7')}`,
  ];

  const cityArray = [
    `${t('jobAlertEmployers.city1')}`,
    `${t('jobAlertEmployers.city2')}`,
    `${t('jobAlertEmployers.city3')}`,
    `${t('jobAlertEmployers.city4')}`,
    `${t('jobAlertEmployers.city5')}`,
    `${t('jobAlertEmployers.city6')}`,
    `${t('jobAlertEmployers.city7')}`,
    `${t('jobAlertEmployers.city8')}`,
  ];

  const [activeTab, setActiveTab] = useState('create');
  const [genderActive, setGenderActive] = React.useState('');

  const [selectedVacancies, setSelectedVacancies] = React.useState('');
  const [isVacanciesPopupOpen, setIsVacanciesPopupOpen] = React.useState(false);

  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [isCountryPopupOpen, setIsCountryPopupOpen] = React.useState(false);

  const [selectedCity, setSelectedCity] = React.useState('');
  const [isCityPopupOpen, setIsCityPopupOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IForm>({
    defaultValues: {
      gender: '',
      age: '20',
      height: '175',
      weight: '65',
      vacancy: '',
      country: '',
      city: '',
    },
  });

  const age = watch('age');
  const height = watch('height');
  const weight = watch('weight');

  const onSubmit: SubmitHandler<IForm> = (data) => {
    console.log('Form data:', data);
    // navigate('/employer/welcome-to-perfomx');
  };

  const handleGenderClick = (gender: string) => {
    setGenderActive(gender);
    setValue('gender', gender);
  };

  return (
    <div className={styles.jobAlert}>
      <header>
        <h1 className={styles.title}>{t('jobAlertEmployers.title')}</h1>
      </header>
      <main className={styles.main}>
        <div className={activeTab === 'archive' ? styles.btnBoxSwitching : styles.btnBox}>
          <button
            className={`${styles.tabBtn} ${activeTab === 'create' && styles.tabBtnActive}`}
            type='button'
            onClick={() => setActiveTab('create')}>
            {t('jobAlertEmployers.create')}
          </button>
          <button
            className={`${styles.tabBtn} ${activeTab === 'archive' && styles.tabBtnActive}`}
            type='button'
            onClick={() => setActiveTab('archive')}>
            {t('jobAlertEmployers.archive')}
          </button>
        </div>
        {activeTab === 'create' && (
          <form className={styles.jobAlertWrapper} onSubmit={handleSubmit(onSubmit)}>
            <article className={styles.titleBox}>
              <p className={styles.desc}>{t('jobAlertEmployers.title')}</p>
              <div className={styles.titleBox}>
                <p className={styles.desc}>{t('jobAlertEmployers.rules')}</p>
                <img width={23} height={23} src={rulesImg} alt='rules' />
              </div>
            </article>
            <textarea
              className={styles.jobAlertTextarea}
              placeholder={t('jobAlertEmployers.textareaPlaceholder')}
              {...register('jobAlertTextarea', { required: t('jobAlertEmployers.textareaError') })}
            />
            {errors.jobAlertTextarea && <p className={styles.errorMessage}>{errors.jobAlertTextarea.message}</p>}
            <h2 className={styles.subTitle}>{t('jobAlertEmployers.show')}</h2>
            <article className={styles.genderBox}>
              <h3 className={styles.subTitle}>{t('jobAlertEmployers.gender')}</h3>
              <div className={styles.genderBtnBox}>
                <label className={`${styles.genderBtn} ${genderActive === 'male' && styles.genderSelection}`}>
                  <input
                    className={styles.createProfileInput}
                    type='radio'
                    value='male'
                    {...register('gender', { required: t('jobAlertEmployers.genderError') })}
                    onClick={() => handleGenderClick('male')}
                    checked={genderActive === 'male'}
                    hidden
                  />
                  {t('jobAlertEmployers.male')}
                </label>

                <label className={`${styles.genderBtn} ${genderActive === 'female' && styles.genderSelection}`}>
                  <input
                    className={styles.createProfileInput}
                    type='radio'
                    value='female'
                    {...register('gender', { required: t('jobAlertEmployers.genderError') })}
                    onClick={() => handleGenderClick('female')}
                    checked={genderActive === 'female'}
                    hidden
                  />
                  {t('jobAlertEmployers.female')}
                </label>
              </div>
              {errors.gender && <p className={styles.errorMessage}>{errors.gender.message}</p>}
            </article>
            <article className={styles.parameters}>
              <label>
                <span className={styles.parametersBox}>
                  <span>{t('jobAlertEmployers.age')}</span>
                  <span>{age}</span>
                </span>
                <input
                  className={styles.inputRange}
                  type='range'
                  min='18'
                  max='100'
                  {...register('age', { required: true, min: 1 })}
                />
              </label>
              {errors.age && <p className={styles.errorMessage}>Age is required and must be at least 1.</p>}
              <label>
                <span className={styles.parametersBox}>
                  <span>{t('jobAlertEmployers.height')}</span>
                  <span>{height}</span>
                </span>
                <input
                  className={styles.inputRange}
                  type='range'
                  min='150'
                  max='200'
                  {...register('height', { required: true })}
                />
              </label>
              <label>
                <span className={styles.parametersBox}>
                  <span>{t('jobAlertEmployers.weight')}</span>
                  <span>{weight}</span>
                </span>
                <input
                  className={styles.inputRange}
                  type='range'
                  min='40'
                  max='150'
                  {...register('weight', { required: true })}
                />
              </label>
            </article>
            <article className={styles.labelContainer}>
              <Input
                background='grey'
                errors={errors}
                value={selectedVacancies}
                popUpOpen={() => setIsVacanciesPopupOpen(true)}
                register={register}
                fieldName='vacancy'
                placeholder={t('jobAlertEmployers.vacancyPlaceholder')}
                errorMassage={t('jobAlertEmployers.vacancyError')}
              />

              <Input
                background='grey'
                errors={errors}
                value={selectedCountry}
                popUpOpen={() => setIsCountryPopupOpen(true)}
                register={register}
                fieldName='country'
                placeholder={t('jobAlertEmployers.countryPlaceholder')}
                errorMassage={t('jobAlertEmployers.countryError')}
              />

              <Input
                background='grey'
                errors={errors}
                value={selectedCity}
                popUpOpen={() => setIsCityPopupOpen(true)}
                register={register}
                fieldName='city'
                placeholder={t('jobAlertEmployers.cityPlaceholder')}
                errorMassage={t('jobAlertEmployers.cityError')}
              />
            </article>
            <article className={styles.subscriptionPrice}>
              <p className={styles.subTitle}>9 {t('jobAlertEmployers.hours')}</p>
              <p className={styles.subTitle}>$ 20</p>
            </article>
            <button className={styles.submitBtn} type='submit'>
              {t('jobAlertEmployers.post')}
            </button>

            <button className={styles.topUpBalanceBtn} type='button'>
              {t('jobAlertEmployers.balance')}
            </button>

            <p className={styles.balance}>{t('jobAlertEmployers.purchase')}</p>
          </form>
        )}
      </main>
      <Footer />
      <PopUpCreateProfile
        title={t('jobAlertEmployers.popUpTitle')}
        popupOpen={isVacanciesPopupOpen}
        setPopupOpen={setIsVacanciesPopupOpen}
        setSelectedObject={(value) => {
          setSelectedVacancies(value);
          setValue('vacancy', value);
        }}
        selectedObject={selectedVacancies}
        itemArray={vacancyArray}
      />
      <PopUpCreateProfile
        title={t('jobAlertEmployers.popUpCountry')}
        popupOpen={isCountryPopupOpen}
        setPopupOpen={setIsCountryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountry(value);
          setValue('country', value);
        }}
        selectedObject={selectedCountry}
        itemArray={countryArray}
      />
      <PopUpCreateProfile
        title={t('jobAlertEmployers.popUpCity')}
        popupOpen={isCityPopupOpen}
        setPopupOpen={setIsCityPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCity(value);
          setValue('city', value);
        }}
        selectedObject={selectedCity}
        itemArray={cityArray}
      />
    </div>
  );
};

export default JobAlertEmployer;
