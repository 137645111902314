import React from 'react';
import Footer from '../../../components/Talent/Footer';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import settings from '../../../img/Profile/settings.svg';
import avatarTalent from '../../../img/Profile/avatar_talent.jpg';
import profileSettings from '../../../img/Profile/profile_settings.svg';
import pointsImg from '../../../img/Profile/points_img.svg';
import iconStar from '../../../img/Profile/icon_star.svg';
import dollar from '../../../img/Profile/dollar.svg';
import boostProfile from '../../../img/Profile/boost_profile.svg';
import becomeTalent from '../../../img/Profile/become_talent.svg';
import notification from '../../../img/Profile/notification.svg';
import getBonuses from '../../../img/Profile/get_bonuses.svg';
import jobAlert from '../../../img/Profile/job_alert.svg';
import hourglass from '../../../img/Profile/hourglass.svg';

import styles from './profile.module.scss';

const Profile = () => {
  const { t } = useTranslation('common');

  const optionsArray = [
    {
      iconOptions: boostProfile,
      link: '/talent/boost-profile',
      title: `${t('profile.title1')}`,
      desc: `${t('profile.desc1')}`,
    },
    {
      iconOptions: becomeTalent,
      link: '/talent/become-a-top-talent',
      title: `${t('profile.title2')}`,
      desc: `${t('profile.desc2')}`,
    },
    {
      iconOptions: notification,
      link: '/talent/receive-notifications',
      title: `${t('profile.title3')}`,
      desc: `${t('profile.desc3')}`,
    },
    {
      iconOptions: getBonuses,
      link: '/talent/get-bonuses',
      title: `${t('profile.title4')}`,
      desc: `${t('profile.desc4')}`,
    },
    { iconOptions: jobAlert, link: '/', title: `${t('profile.title5')}`, desc: `${t('profile.desc5')}` },
  ];

  return (
    <div className={styles.profile}>
      <header className={styles.header}>
        <img width={28} height={28} src={settings} alt='settings' />
        <article className={styles.iconBox}>
          <img width={128} height={128} src={avatarTalent} alt='avatar talent' />
          <p className={styles.title}>
            Sasha / <span>Dancer</span>
          </p>
          <Link className={styles.link} to='/talent/view-profile'>
            {t('profile.viewProfile')}
          </Link>
        </article>
        <Link to='/talent/edit-profile'>
          <img width={28} height={28} src={profileSettings} alt='profile settings' />
        </Link>
      </header>
      <main className={styles.main}>
        <section className={styles.accountOverview}>
          <article className={styles.points}>
            <img width={34} height={34} src={pointsImg} alt='points' />
            <p className={styles.pointTitle}>424</p>
            <p className={styles.pointDesc}>{t('profile.positions')}</p>
          </article>
          <article className={styles.points}>
            <img width={34} height={34} src={iconStar} alt='star' />
            <p className={styles.pointTitle}>19</p>
            <p className={styles.pointDesc}>{t('profile.favorites')}</p>
          </article>
          <article className={styles.points}>
            <img width={34} height={34} src={hourglass} alt='hourglass' />
            <p className={styles.finishedTitle}>{t('profile.finished')}</p>
            <p className={styles.finishedDesc}>{t('profile.premium')}</p>
          </article>
        </section>
        <section className={styles.balance}>
          <img src={dollar} alt='dollar' />
          <div>
            <p className={styles.balanceDesc}>{t('profile.balance')}</p>
            <p className={styles.balanceDesc}>$0</p>
          </div>
          <button className={styles.topUpBtn} type='button'>
            {t('profile.topUp')}
          </button>
        </section>
        <section>
          <h2 className={styles.optionsTitle}>{t('profile.options')}</h2>
          <article className={styles.optionsContainer}>
            {optionsArray.map((item, index) => (
              <Link to={item.link} className={styles.optionsBox} key={index}>
                <img src={item.iconOptions} alt={item.title} />
                <span>
                  <span className={styles.subTitle}>{item.title}</span>
                  <span className={styles.desc}>{item.desc}</span>
                </span>
              </Link>
            ))}
          </article>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Profile;
