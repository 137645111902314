import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PopUpCreateProfile from '../../../components/Talent/PopUpCreateProfile';
import Input from '../../../components/Input';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ProgressBar from '../../../components/Talent/ProgressBar';
import backArrow from '../../../img/Profile/back_arrow.svg';
import successfully from '../../../img/Profile/successfully.svg';
import calendarImg from '../../../img/Ctrate-form/calendar.svg';
import defaultPhoto from '../../../img/Profile/defaultPhoto_edit.jpg';
import crossClose from '../../../img/Ctrate-form/cross-close.svg';

import styles from './editProfile.module.scss';

interface FormValues {
  name: string;
  age: number;
  height: number;
  weight: number;
  appearance: string;
  languageSkills: string;
  workExperience: string;
  aboutYourself: string;
  location: string;
  calendar: string;
  contractCountry: string;
  contractPeriod: string;
  expectedSalary: string;
  [key: string]: any;
  // [key: `photo${number}`]: FileList | null; WebStorm - ругается
}

const EditProfile = () => {
  const { t } = useTranslation('common');
  const countries = [
    `${t('jobApplication.country1')}`,
    `${t('jobApplication.country2')}`,
    `${t('jobApplication.country3')}`,
    `${t('jobApplication.country4')}`,
    `${t('jobApplication.country5')}`,
  ];

  const languages = [
    `${t('jobApplication.language1')}`,
    `${t('jobApplication.language2')}`,
    `${t('jobApplication.language3')}`,
  ];

  const countriesForWorkArray = [
    `${t('jobApplication.countriesForWorCountry1')}`,
    `${t('jobApplication.countriesForWorCountry2')}`,
    `${t('jobApplication.countriesForWorCountry3')}`,
    `${t('jobApplication.countriesForWorCountry4')}`,
    `${t('jobApplication.countriesForWorCountry5')}`,
    `${t('jobApplication.countriesForWorCountry6')}`,
    `${t('jobApplication.countriesForWorCountry7')}`,
  ];

  const contractPeriodArray = [
    `${t('jobApplication.contractPeriodItem1')}`,
    `${t('jobApplication.contractPeriodItem2')}`,
    `${t('jobApplication.contractPeriodItem3')}`,
    `${t('jobApplication.contractPeriodItem4')}`,
  ];

  const expectedSalaryArray = [
    `${t('jobApplication.salaryItem1')}`,
    `${t('jobApplication.salaryItem2')}`,
    `${t('jobApplication.salaryItem3')}`,
    `${t('jobApplication.salaryItem4')}`,
    `${t('jobApplication.salaryItem5')}`,
    `${t('jobApplication.salaryItem6')}`,
  ];

  const citizenshipArray = [
    t('createProfile1.country1'),
    t('createProfile1.country2'),
    t('createProfile1.country3'),
    t('createProfile1.country4'),
    t('createProfile1.country5'),
    t('createProfile1.country6'),
    t('createProfile1.country7'),
  ];

  const professionArray = [
    t('createProfile1.profession1'),
    t('createProfile1.profession2'),
    t('createProfile1.profession3'),
    t('createProfile1.profession4'),
    t('createProfile1.profession5'),
    t('createProfile1.profession6'),
    t('createProfile1.profession7'),
    t('createProfile1.profession8'),
    t('createProfile1.profession9'),
    t('createProfile1.profession10'),
    t('createProfile1.profession11'),
    t('createProfile1.profession12'),
    t('createProfile1.profession13'),
    t('createProfile1.profession14'),
    t('createProfile1.profession15'),
    t('createProfile1.profession16'),
  ];

  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [isAppearancePopupOpen, setIsAppearancePopupOpen] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState('');
  const [isLanguagePopupOpen, setIsLanguagePopupOpen] = React.useState(false);

  const [isCountryPopupOpen, setIsCountryPopupOpen] = React.useState(false);
  const [selectedCountryForWork, setSelectedCountryForWork] = React.useState('');

  const [isContractPeriodPopupOpen, setIsContractPeriodPopupOpen] = React.useState(false);
  const [selectedContractPeriod, setSelectedContractPeriod] = React.useState('');

  const [isProfessionPopupOpen, setIsProfessionPopupOpen] = useState(false);
  const [selectedProfession, setSelectedProfession] = useState('');

  const [isExpectedSalaryPopupOpen, setIsExpectedSalaryPopupOpen] = React.useState(false);
  const [selectedExpectedSalary, setSelectedExpectedSalary] = React.useState('');

  const [isCitizenshipPopupOpen, setIsCitizenshipPopupOpen] = useState(false);
  const [selectedCitizenship, setSelectedCitizenship] = React.useState('');

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const [informationPopUp, setInformationPopUp] = useState(false);

  const {
    register,
    handleSubmit,
    // formState: { errors },
    setValue,
    watch,
    // setError,
    // clearErrors,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      age: 25,
      height: 165,
      weight: 55,
      appearance: '',
      languageSkills: '',
      workExperience: '',
      aboutYourself: '',
      location: '',
      calendar: '',
      contractCountry: '',
      contractPeriod: '',
      expectedSalary: '',
    },
  });

  const age = watch('age');
  const height = watch('height');
  const weight = watch('weight');

  const navigate = useNavigate();

  const [mainPhotos, setMainPhotosPhotos] = useState(Array(2).fill(null));
  const [uploadedPhotos, setUploadedPhotos] = useState(Array(8).fill(null));

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setValue('calendar', date?.toISOString() || '');
  };

  const onSubmit = (data: FormValues) => {
    if (informationPopUp) {
      setTimeout(() => {
        setInformationPopUp(false);
        navigate('/talent/profile');
        console.log('Edit profile:', data);
      }, 3000);
    }
  };

  const handleMainPhotoChange = (index) => (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newPhotos = [...mainPhotos];
        newPhotos[index] = e.target.result;
        setMainPhotosPhotos(newPhotos);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleMainPhotoRemove = (index) => () => {
    const newPhotos = [...mainPhotos];
    newPhotos[index] = null;
    setMainPhotosPhotos(newPhotos);
  };

  const handlePhotoChange = (index) => (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newPhotos = [...uploadedPhotos];
        newPhotos[index] = e.target.result;
        setUploadedPhotos(newPhotos);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handlePhotoRemove = (index) => () => {
    const newPhotos = [...uploadedPhotos];
    newPhotos[index] = null;
    setUploadedPhotos(newPhotos);
  };

  return (
    <div className={styles.createProfileJobApplication}>
      <header className={styles.header}>
        <Link className={styles.linkProfile} to='/talent/profile'>
          <img src={backArrow} alt='back arrow' />
        </Link>
        <h1 className={styles.titlePage}>{t('talentProfileEdit.title')}</h1>
      </header>
      <main>
        <Link
          className={styles.rules}
          to='/talent/create-profile-5/photo-placement-rules'
          state={{ from: '/talent/edit-profile' }}>
          {t('talentProfileEdit.rules')}
        </Link>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <h3 className={styles.title}>{t('talentProfileEdit.main')}</h3>
          <ul className={styles.formContainer}>
            {mainPhotos.map((photo, index) => (
              <li key={index} className={styles.imgBox}>
                <label className={styles.uploadPhoto} htmlFor={`mainPhotoInput-${index}`}>
                  <img width={80} height={80} src={photo || defaultPhoto} alt={`uploaded photo ${index + 1}`} />
                </label>
                {photo && (
                  <button type='button' className={styles.removeButton} onClick={handleMainPhotoRemove(index)}>
                    <img width={16} height={16} src={crossClose} alt='cross close' />
                  </button>
                )}
                <input
                  id={`mainPhotoInput-${index}`}
                  type='file'
                  accept='image/*'
                  {...register(`photo${index}`)}
                  onChange={handleMainPhotoChange(index)}
                  style={{ display: 'none' }}
                />
              </li>
            ))}
          </ul>
          <h3 className={styles.title}>{t('talentProfileEdit.additionalPhotos')}</h3>
          <ul className={styles.formContainer}>
            {uploadedPhotos.map((photo, index) => (
              <li key={index} className={styles.imgBox}>
                <label className={styles.uploadPhoto} htmlFor={`photoInput-${index}`}>
                  <img width={80} height={80} src={photo || defaultPhoto} alt={`uploaded photo ${index + 1}`} />
                </label>
                {photo && (
                  <button type='button' className={styles.removeButton} onClick={handlePhotoRemove(index)}>
                    <img width={16} height={16} src={crossClose} alt='cross close' />
                  </button>
                )}
                <input
                  id={`photoInput-${index}`}
                  type='file'
                  accept='image/*'
                  {...register(`photo${index}`)}
                  onChange={handlePhotoChange(index)}
                  style={{ display: 'none' }}
                />
              </li>
            ))}
          </ul>

          <input className={styles.createProfileInput} placeholder={t('createProfile1.name')} {...register('name')} />

          <Input
            background='grey'
            value={selectedCitizenship}
            popUpOpen={() => setIsCitizenshipPopupOpen(true)}
            fieldName='citizenship'
            placeholder={t('createProfile1.citizenship')}
          />

          <Input
            background='grey'
            value={selectedProfession}
            popUpOpen={() => setIsProfessionPopupOpen(true)}
            fieldName='profession'
            placeholder={t('createProfile1.profession')}
          />

          <label>
            <span className={styles.parametersBox}>
              <span>{t('jobApplication.age')}</span>
              <span>{age}</span>
            </span>
            <input className={styles.inputRange} type='range' min='18' max='100' {...register('age')} />
          </label>

          <label>
            <span className={styles.parametersBox}>
              <span>{t('jobApplication.height')}</span>
              <span>{height}</span>
            </span>
            <input className={styles.inputRange} type='range' min='150' max='200' {...register('height')} />
          </label>

          <label>
            <span className={styles.parametersBox}>
              <span>{t('jobApplication.weight')}</span>
              <span>{weight}</span>
            </span>
            <input className={styles.inputRange} type='range' min='40' max='150' {...register('weight')} />
          </label>

          <Input
            background='grey'
            value={selectedCountry}
            popUpOpen={() => setIsAppearancePopupOpen(true)}
            fieldName='appearance'
            placeholder={t('jobApplication.appearance')}
          />

          <Input
            background='grey'
            value={selectedLanguage}
            popUpOpen={() => setIsLanguagePopupOpen(true)}
            fieldName='languageSkills'
            placeholder={t('jobApplication.languageSkill')}
          />

          <h3 className={styles.title}>{t('jobApplication.experience')}</h3>
          <textarea
            className={styles.workExperience}
            placeholder={t('jobApplication.worked')}
            {...register('workExperience')}
          />
          <h3 className={styles.title}>{t('jobApplication.aboutYourselfTitle')}</h3>
          <textarea
            className={styles.aboutYourself}
            placeholder={t('jobApplication.aboutYourself')}
            {...register('aboutYourself')}
          />
          <h3 className={styles.title}>{t('jobApplication.details')}</h3>

          <input
            className={styles.createProfileInput}
            type='text'
            name='location'
            placeholder={t('jobApplication.location')}
            {...register('location')}
          />
          <p className={styles.desc}>{t('jobApplication.languageIndication')}</p>
          <label className={styles.calendarBox}>
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat='dd/MM/yyyy'
              className={styles.createProfileInput}
              placeholderText={t('jobApplication.startContract')}
            />
            <img className={styles.calendarImg} width={30} height={30} src={calendarImg} alt='calendar' />
          </label>
          <Input
            background='grey'
            value={selectedCountryForWork}
            popUpOpen={() => setIsCountryPopupOpen(true)}
            fieldName='contractCountry'
            placeholder={t('jobApplication.contractCountry')}
          />

          <Input
            background='grey'
            value={selectedContractPeriod}
            popUpOpen={() => setIsContractPeriodPopupOpen(true)}
            fieldName='contractPeriod'
            placeholder={t('jobApplication.contractPeriod')}
          />

          <Input
            background='grey'
            value={selectedExpectedSalary}
            popUpOpen={() => setIsExpectedSalaryPopupOpen(true)}
            fieldName='expectedSalary'
            placeholder={t('jobApplication.salary')}
          />
          <button className={styles.save} type='submit' onClick={() => setInformationPopUp(true)}>
            {t('talentProfileEdit.saveBtn')}
          </button>
        </form>
        {informationPopUp && (
          <section className={styles.informationPopUp}>
            <article className={styles.informationPopUpContainer}>
              <img width={86} height={86} src={successfully} alt='successfully' />
              <p className={styles.informationDesc}>{t('talentProfileEdit.informationPopUp')}</p>
            </article>
          </section>
        )}
      </main>
      <PopUpCreateProfile
        title={t('createProfile1.citizenship')}
        popupOpen={isCitizenshipPopupOpen}
        setPopupOpen={setIsCitizenshipPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCitizenship(value);
          setValue('citizenship', value);
        }}
        selectedObject={selectedCitizenship}
        itemArray={citizenshipArray}
      />
      <PopUpCreateProfile
        title={t('jobApplication.appearance')}
        popupOpen={isAppearancePopupOpen}
        setPopupOpen={setIsAppearancePopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountry(value);
          setValue('appearance', value);
        }}
        selectedObject={selectedCountry}
        itemArray={countries}
      />
      <PopUpCreateProfile
        title={t('createProfile1.profession')}
        popupOpen={isProfessionPopupOpen}
        setPopupOpen={setIsProfessionPopupOpen}
        setSelectedObject={(value) => {
          setSelectedProfession(value);
          setValue('profession', value);
        }}
        selectedObject={selectedProfession}
        itemArray={professionArray}
      />
      <PopUpCreateProfile
        title={t('jobApplication.languageSkill')}
        popupOpen={isLanguagePopupOpen}
        setPopupOpen={setIsLanguagePopupOpen}
        setSelectedObject={(value) => {
          setSelectedLanguage(value);
          setValue('languageSkills', value);
        }}
        selectedObject={selectedLanguage}
        itemArray={languages}
      />
      <PopUpCreateProfile
        title={t('jobApplication.contractCountry')}
        popupOpen={isCountryPopupOpen}
        setPopupOpen={setIsCountryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountryForWork(value);
          setValue('contractCountry', value);
        }}
        selectedObject={selectedCountryForWork}
        itemArray={countriesForWorkArray}
      />
      <PopUpCreateProfile
        title={t('jobApplication.contractPeriod')}
        popupOpen={isContractPeriodPopupOpen}
        setPopupOpen={setIsContractPeriodPopupOpen}
        setSelectedObject={(value) => {
          setSelectedContractPeriod(value);
          setValue('contractPeriod', value);
        }}
        selectedObject={selectedContractPeriod}
        itemArray={contractPeriodArray}
      />
      <PopUpCreateProfile
        title={t('jobApplication.salary')}
        popupOpen={isExpectedSalaryPopupOpen}
        setPopupOpen={setIsExpectedSalaryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedExpectedSalary(value);
          setValue('expectedSalary', value);
        }}
        selectedObject={selectedExpectedSalary}
        itemArray={expectedSalaryArray}
      />
    </div>
  );
};

export default EditProfile;
