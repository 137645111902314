import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../../../components/Talent/ProgressBar';
import CreateProfileNavigation from '../../../components/Talent/CreateProfileNavigation/CreateProfileNavigation';
import defaultPhoto from '../../../img/Ctrate-form/example_of_ a_passport.jpg';
import cameraUploaded from '../../../img/Ctrate-form/camera_uploaded.svg';
import crossClose from '../../../img/Ctrate-form/cross-close.svg';
import { useTranslation } from 'react-i18next';
import passportBg from '../../../img/Ctrate-form/passport_bg.svg';

import styles from './createProfilePassport.module.scss';

const CreateProfilePassport = () => {
  const { t } = useTranslation('common');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log(data);
    navigate('/talent/congratulations-job-application');
    /*try {
      const formData = new FormData();
      formData.append('photo', data.photo[0]);

      const response = await fetch('/api/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        console.log('Photo uploaded successfully');
      } else {
        console.error('Upload failed');
      }
    } catch (error) {
      console.error('Error uploading photo', error);
    }*/
  };

  const handlePhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedPhoto(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handlePhotoRemove = () => {
    setUploadedPhoto(null);
  };

  return (
    <section className={styles.createProfileVerification}>
      <ProgressBar activeSteps={5} formSteps={5} />
      <div className={styles.titleBox}>
        <img className={styles.img} width={306} height={83} src={passportBg} alt='background' />
        <h1 className={styles.titlePage}>{t('passportVer.title')}</h1>
      </div>
      <p className={styles.desc}>{t('passportVer.status')}</p>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <label className={styles.uploadPhoto} htmlFor='photoInput'>
          <img width={240} height={170} src={cameraUploaded} alt='camera uploaded' />
        </label>
        <p className={styles.desc}>{t('passportVer.copyPassport')}</p>
        <input
          id='photoInput'
          type='file'
          accept='image/*'
          {...register('photo', { required: true })}
          onChange={handlePhotoChange}
          style={{ display: 'none' }}
        />
        <article className={styles.imgBox}>
          <img
            width={250}
            height={166}
            className={styles.uploadedPhoto}
            src={uploadedPhoto || defaultPhoto}
            alt='uploaded photo'
          />
          <button type='button' className={styles.removeButton} onClick={handlePhotoRemove}>
            <img src={crossClose} alt='cross close' />
          </button>
          {errors.photo && <span className={styles.errorMessage}>Please upload a photo</span>}
        </article>
        <p className={styles.desc}>{t('passportVer.attach')}</p>
        <p className={styles.descStatus}>{t('passportVer.confirmed')}</p>
        <CreateProfileNavigation backLink='/talent/create-profile-2' />
      </form>
    </section>
  );
};

export default CreateProfilePassport;
