import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from '../../../components/Employer/Footer';
import club from '../../../img/Jobs/club.jpg';
import club2 from '../../../img/Jobs/club_2.jpg';
import club3 from '../../../img/Jobs/club_3.jpg';
import backArrow from '../../../img/Jobs/back_arrow.svg';
// import editingProfile from '../../../img/Profile/editing_profile.svg';
import favorites from '../../../img/Jobs/favorites.svg';
import checkMark from '../../../img/Jobs/check_mark.svg';
import reviewsAvatar from '../../../img/Jobs/avatar.svg';
import ratingStarActive from '../../../img/Jobs/rating_star_active.svg';
import ratingStar from '../../../img/Jobs/rating_star.svg';
import warning from '../../../img/Jobs/warning.svg';
import closeArrow from '../../../img/Jobs/close_arrow.svg';
import LinkButton from '../../../components/Employer/LinkButton';

import styles from './viewProfileTalent.module.scss';

const jobsArray = [club3, club2, club, club, club2, club3, club, club2];

const ViewProfileTalent = () => {
  const { t } = useTranslation('common');

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      stars: 0,
      review: '',
    },
  });

  const [open, setOpen] = useState(false);
  const [blockedPopUp, setBlockedPopUp] = useState(false);
  const [reviewPopUp, setReviewPopUp] = useState(false);
  const [selectVacancy, setSelectVacancy] = useState(false);

  const blockedPopUpFunction = () => {
    if (blockedPopUp) {
      setTimeout(() => {
        setBlockedPopUp(false);
      }, 3000);
    }
    return blockedPopUp;
  };

  const settings = {
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    dotsClass: styles.dots,
  };

  /*const settingsTalent = {
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 2,
    dots: false,
  };*/

  const rating = 5;
  const ratingEmployer = 4.2;
  const roundedRating = Math.round(ratingEmployer);

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className={styles.viewProfileTalent}>
      <header className={styles.header}>
        <nav className={styles.navBox}>
          <Link to='/employer/talents'>
            <img width={32} height={32} src={backArrow} alt='back arrow' />
          </Link>
          {/*<Link to='/'>
            <img width={32} height={32} src={editingProfile} alt='editing profile' />
          </Link>*/}
        </nav>
        <Slider {...settings} className={styles.sliderBox}>
          {jobsArray.map((item, index) => (
            <img width={390} height={362} src={item} alt='club.jpg' key={index} />
          ))}
        </Slider>
      </header>
      <main className={styles.main}>
        <article className={styles.talentBox}>
          <div>
            <p className={styles.talentTitle}>
              <span className={styles.talentTitleBolt}>Sasha</span>
              <span className={styles.onlineDot} />
            </p>
            <p className={styles.desc}>Turkey, Istanbul</p>
            <p className={`${styles.desc} ${styles.online}`}>{t('employerProfileTalent.online')} a day ago</p>
          </div>
          <aside>
            <p className={styles.rating}>
              {t('employerProfileTalent.rating')} 4.96 <img width={18} height={18} src={favorites} alt='favorites' />{' '}
            </p>
            <p className={styles.reviews}>{t('employerProfileTalent.reviews')} 168</p>
          </aside>
        </article>
        <article className={styles.chatContainerBtn}>
          <div className={styles.chatBoxBtn}>
            <button className={styles.chatBtn} type='button'>
              {t('employerProfileTalent.chatBtn')}
            </button>
            <button className={styles.chatBtn} type='button' onClick={() => setSelectVacancy(!selectVacancy)}>
              {t('employerProfileTalent.hireBtn')}
            </button>
          </div>
        </article>

        <hr className={styles.line} />
        <h2 className={styles.title}>{t('employerProfileTalent.information')}</h2>
        <ul className={styles.box}>
          <li className={styles.desc}>{t('employerProfileTalent.registered')} 25.05.2022</li>
          <li className={styles.desc}>{t('employerProfileTalent.contracts')} 10</li>
          <li className={styles.desc}>{t('employerProfileTalent.arbitration')} 5</li>
          <li className={styles.verified}>
            <span>{t('employerProfileTalent.verified')}</span>
            <img width={15} height={14} src={checkMark} alt={t('employerProfileTalent.verified')} />
          </li>
        </ul>
        <h2 className={styles.title}>{t('employerProfileTalent.aboutMe')}</h2>
        <article className={`${styles.text} ${open && styles.textOpen}`}>
          <p className={`${open ? styles.textBox : styles.ellipsis} ${styles.desc}`}>
            About me: Hello, my name is Sasha. I'm 22 years old, and I'm from Istanbul. I've been dancing since I was a
            child, and it has always been my passion. Over the years, I've trained in various styles, including ballet,
            contemporary, and hip-hop. Dancing allows me to express myself in ways that words cannot. I've performed on
            many stages and have had the opportunity to work with some incredible choreographers. My goal is to continue
            growing as a dancer and to inspire others through my art.
          </p>
        </article>
        <button className={`${styles.expand}`} onClick={() => setOpen(!open)} type='button'>
          {open ? t('employerProfileTalent.hide') : t('employerProfileTalent.expand')}
        </button>
        {/*<h2 className={styles.title}>Similar Talents:</h2>
        <article className={styles.slider}>
          <Slider {...settingsTalent} className={styles.vacanciesWrapper}>
            {mocDataSimilarTalent.map((item, index) => (
              <div className={styles.vacanciesContainer}>
                {item.top && <p className={styles.top}>{item.top}</p>}
                {item.applicants > 0 && (
                  <div className={styles.group}>
                    <img width={16} height={16} src={groupImg} alt='group' />
                    <p className={styles.groupDesc}>{item.applicants}</p>
                  </div>
                )}
                <img
                  className={styles.favorites}
                  width={24}
                  height={24}
                  src={item.favorites ? favoritesImg : noFavoritesImg}
                  alt='favorites'
                />
                <img
                  className={styles.vacanciesImg}
                  width={171}
                  height={171}
                  src={item.photoPlaceWork[0].url}
                  alt='house'
                />
              </div>
            ))}
          </Slider>
        </article>*/}
        <article className={styles.questionnaire}>
          <h2 className={styles.title}>{t('employerProfileTalent.questionnaire')}</h2>
          <p className={styles.desc}>
            {t('employerProfileTalent.profession')} <span>Dancer</span>
          </p>
          <p className={styles.desc}>
            {t('employerProfileTalent.appearance')} <span>Slavic, 22 years old, 163 cm, 55 kg.</span>{' '}
          </p>
          <p className={styles.desc}>
            {t('employerProfileTalent.language')} <span>Russian, English</span>{' '}
          </p>
          <p className={styles.desc}>
            {t('employerProfileTalent.experience')} <span>From 3 years</span>
          </p>
          <p className={styles.desc}>
            {t('employerProfileTalent.location')} <span>Poland</span>
          </p>
          <p className={styles.desc}>
            {t('employerProfileTalent.start')} <span>Within a month</span>{' '}
          </p>
          <p className={styles.desc}>
            {t('employerProfileTalent.contractDuration')} <span>From 3 to 4 months</span>{' '}
          </p>
          <p className={styles.desc}>
            {t('employerProfileTalent.contractCountry')} <span>Turkey</span>
          </p>
          <p className={styles.desc}>
            {t('employerProfileTalent.contractCity')} <span>Istanbul</span>{' '}
          </p>
          <p className={styles.desc}>
            {t('employerProfileTalent.salary')} <span>From $2000 to $3000</span>{' '}
          </p>
        </article>

        <article className={styles.reviewsBox}>
          <h2 className={styles.title}>{t('employerProfileTalent.writeReview')}</h2>
          <button type='button' className={styles.expand} onClick={() => setReviewPopUp(!reviewPopUp)}>
            {t('employerProfileTalent.leaveReview')}
          </button>
        </article>
        <article className={styles.talentReviews}>
          <div className={styles.talentWrapper}>
            <div className={styles.talentInfo}>
              <img width={62} height={62} src={reviewsAvatar} alt='reviews Avatar' />
              <div className={styles.talentContainer}>
                <div className={styles.talentNameBox}>
                  <p className={styles.talentName}>Sergey S.</p>
                  {/*<img width={16} height={16} src={check} alt='check' />*/}
                </div>
                <div className={styles.talentNameBox}>
                  <p className={styles.talentDesc}>Moscow, </p>
                  <p className={styles.talentDesc}>Russia</p>
                </div>
              </div>
            </div>
            <div className={styles.talentContainer}>
              <p className={styles.ratingDesc}>{t('employerProfileTalent.performersRating')}</p>
              <div className={styles.rating}>
                {Array(roundedRating)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      key={`active-star-${index}`}
                      width={14}
                      height={14}
                      src={ratingStarActive}
                      alt='rating active star'
                    />
                  ))}
                {Array(rating - roundedRating)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      key={`inactive-star-${index}`}
                      width={14}
                      height={14}
                      src={ratingStar}
                      alt='rating inactive star'
                    />
                  ))}
                <p className={styles.ratingDesc}>{ratingEmployer}</p>
              </div>
            </div>
          </div>
          <aside className={styles.reviewsDesc}>
            Hello.  My partner with sufficient work experience, eloquent speech, citizenship of the Russian Federation
            (Republic of Belarus), and a passport is ready to complete your task. All responsibility will lie with me.
            Please write or, better yet, call to discuss the details of the task, and then entrust it to me and my
            colleague. Thank you.
          </aside>
        </article>
        <article className={styles.talentReviews}>
          <div className={styles.talentWrapper}>
            <div className={styles.talentInfo}>
              <img width={62} height={62} src={reviewsAvatar} alt='reviews Avatar' />
              <div className={styles.talentContainer}>
                <div className={styles.talentNameBox}>
                  <p className={styles.talentName}>Sergey S.</p>
                  {/*<img width={16} height={16} src={check} alt='check' />*/}
                </div>
                <div className={styles.talentNameBox}>
                  <p className={styles.talentDesc}>Moscow, </p>
                  <p className={styles.talentDesc}>Russia</p>
                </div>
              </div>
            </div>
            <div className={styles.talentContainer}>
              <p className={styles.ratingDesc}>Performer's rating:</p>
              <div className={styles.rating}>
                {Array(roundedRating)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      key={`active-star-${index}`}
                      width={14}
                      height={14}
                      src={ratingStarActive}
                      alt='rating active star'
                    />
                  ))}
                {Array(rating - roundedRating)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      key={`inactive-star-${index}`}
                      width={14}
                      height={14}
                      src={ratingStar}
                      alt='rating inactive star'
                    />
                  ))}
                <p className={styles.ratingDesc}>{ratingEmployer}</p>
              </div>
            </div>
          </div>
          <aside className={styles.reviewsDesc}>
            Hello.  My partner with sufficient work experience, eloquent speech, citizenship of the Russian Federation
            (Republic of Belarus), and a passport is ready to complete your task. All responsibility will lie with me.
            Please write or, better yet, call to discuss the details of the task, and then entrust it to me and my
            colleague. Thank you.
          </aside>
        </article>
        <article className={styles.talentReviews}>
          <div className={styles.talentWrapper}>
            <div className={styles.talentInfo}>
              <img width={62} height={62} src={reviewsAvatar} alt='reviews Avatar' />
              <div className={styles.talentContainer}>
                <div className={styles.talentNameBox}>
                  <p className={styles.talentName}>Sergey S.</p>
                  {/*<img width={16} height={16} src={check} alt='check' />*/}
                </div>
                <div className={styles.talentNameBox}>
                  <p className={styles.talentDesc}>Moscow, </p>
                  <p className={styles.talentDesc}>Russia</p>
                </div>
              </div>
            </div>
            <div className={styles.talentContainer}>
              <p className={styles.ratingDesc}>Performer's rating:</p>
              <div className={styles.rating}>
                {Array(roundedRating)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      key={`active-star-${index}`}
                      width={14}
                      height={14}
                      src={ratingStarActive}
                      alt='rating active star'
                    />
                  ))}
                {Array(rating - roundedRating)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      key={`inactive-star-${index}`}
                      width={14}
                      height={14}
                      src={ratingStar}
                      alt='rating inactive star'
                    />
                  ))}
                <p className={styles.ratingDesc}>{ratingEmployer}</p>
              </div>
            </div>
          </div>
          <aside className={styles.reviewsDesc}>
            Hello.  My partner with sufficient work experience, eloquent speech, citizenship of the Russian Federation
            (Republic of Belarus), and a passport is ready to complete your task. All responsibility will lie with me.
            Please write or, better yet, call to discuss the details of the task, and then entrust it to me and my
            colleague. Thank you.
          </aside>
        </article>
      </main>
      {!reviewPopUp && <Footer />}
      {blockedPopUpFunction() && (
        <article className={styles.warning}>
          <img width={86} height={86} src={warning} alt='warning - user is blocked' />
          <p className={styles.warningDesc}>User is blocked</p>
        </article>
      )}
      {reviewPopUp && (
        <article className={styles.reviewPopUp}>
          <div className={styles.headerBox}>
            <button type='button' onClick={() => setReviewPopUp(!reviewPopUp)}>
              <img width={22} height={22} src={closeArrow} alt='close arrow' />
            </button>
            <h4 className={styles.reviewPopUpTitle}>
              Sasha / <span>Dancer</span>
            </h4>
          </div>
          <h4 className={styles.reviewPopUpSubTitle}>{t('employerProfileTalent.rate')}</h4>
          <form className={styles.rate} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.stars}>
              {[...Array(5)].map((_, index) => (
                <label className={styles.label} key={index}>
                  <input
                    type='radio'
                    value={index + 1}
                    {...register('stars', { required: t('employerProfileTalent.rateError') })}
                    hidden
                  />
                  <img
                    width={33}
                    height={33}
                    src={index < watch('stars') ? ratingStarActive : ratingStar}
                    alt={index < watch('stars') ? 'active star' : 'inactive star'}
                  />
                </label>
              ))}
            </div>
            {errors.stars && <p className={styles.errorMessage}>{errors.stars.message}</p>}
            <h4 className={styles.reviewPopUpSubTitle}>{t('employerProfileTalent.reviews')}</h4>
            <p className={styles.reviewPopUpDesc}>{t('employerProfileTalent.reviewDesc')}</p>
            <textarea
              className={styles.textarea}
              {...register('review', { required: t('employerProfileTalent.reviewError') })}
            />
            {errors.review && <p className={styles.errorMessage}>{errors.review.message}</p>}
            <button className={styles.reviewPopUpSubmit} type='submit'>
              {t('employerProfileTalent.sendSend')}
            </button>
          </form>
        </article>
      )}
      {selectVacancy && (
        <section className={styles.selectVacancyPopUp}>
          <article className={styles.selectVacancyPopUpContainer}>
            <img src={warning} alt='warning' />
            <p className={styles.selectVacancyPopUpDesc}>{t('employerProfileTalent.selectDesc')}</p>
            <LinkButton title='Choose' link='/employer/all-my-jobs' />
          </article>
        </section>
      )}
    </div>
  );
};

export default ViewProfileTalent;
