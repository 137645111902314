import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './photoPlacementRules.module.scss';

const PhotoPlacementRules = () => {
  const { t } = useTranslation('common');
  const location = useLocation();
  const navigate = useNavigate();

  const previousPage = location.state?.from || '/talent/create-profile-3';

  const placementRulesArray = [
    { desc: t('photoPlacementRules.permissionList1') },
    {
      desc: t('photoPlacementRules.permissionList2'),
    },
    {
      desc: t('photoPlacementRules.permissionList3'),
    },
  ];

  const photosNotAllowedArray = [
    { desc: t('photoPlacementRules.notAllowed1') },
    {
      desc: t('photoPlacementRules.notAllowed2'),
    },
    {
      desc: t('photoPlacementRules.notAllowed3'),
    },
    {
      desc: t('photoPlacementRules.notAllowed4'),
    },
    {
      desc: t('photoPlacementRules.notAllowed5'),
    },
    {
      desc: t('photoPlacementRules.notAllowed6'),
    },
    {
      desc: t('photoPlacementRules.notAllowed7'),
    },
    {
      desc: t('photoPlacementRules.notAllowed8'),
    },
  ];

  return (
    <section className={styles.photoPlacementRules}>
      <h1 className={styles.title}>{t('photoPlacementRules.title')}</h1>
      <h2 className={styles.subTitle}>{t('photoPlacementRules.permission')}</h2>
      <ul className={styles.welcomeItems}>
        {placementRulesArray.map((item, index) => (
          <li className={styles.welcomeItem} key={index}>
            <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M2 6.36842L6.03333 11L13 3' stroke='#219653' strokeWidth='2.5' strokeLinecap='round' />
            </svg>
            <p className={styles.welcomeItemDesc}>{item.desc}</p>
          </li>
        ))}
      </ul>
      <h2 className={styles.subTitle}>{t('photoPlacementRules.notAllowedTitle')}</h2>
      <ul className={styles.welcomeItems}>
        {photosNotAllowedArray.map((item, index) => (
          <li className={styles.welcomeItem} key={index}>
            <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M2 6.36842L6.03333 11L13 3' stroke='#219653' strokeWidth='2.5' strokeLinecap='round' />
            </svg>
            <p className={styles.welcomeItemDesc}>{item.desc}</p>
          </li>
        ))}
      </ul>
      <p className={styles.desc}>{t('photoPlacementRules.administration')}</p>
      <button type='button' className={styles.link} onClick={() => navigate(previousPage)}>
        {t('photoPlacementRules.link')}
      </button>
    </section>
  );
};

export default PhotoPlacementRules;
