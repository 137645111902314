import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Talent/Footer';
import backArrow from '../../../img/Chat/back_arrow.svg';
import iconMenu from '../../../img/Chat/icon_menu.svg';
import checkmarks from '../../../img/Chat/checkmarks.svg';
import arrow from '../../../img/Chat/arrow.svg';
import { useStore } from '../../../hooks/useRootStore';
import { getSnapshot } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';
import avatarAgency from '../../../img/Chat/avatar_agency.jpg';
import letterIcon from '../../../img/Chat/letter_icon.svg';

import styles from './chatEmployerTalent.module.scss';

const ChatEmployerTalent = observer(() => {
  const { t } = useTranslation('common');

  const templateTalentMocData = [
    `${t('generalChat.templateTalent1')}`,
    `${t('generalChat.templateTalent2')}`,
    `${t('generalChat.templateTalent3')}`,
  ];

  const {
    chatStore: { messages, setMessages /*templateTalentMocData*/ },
  } = useStore();

  const [inputMessage, setInputMessage] = useState('');

  // const employerTemplates = getSnapshot(employerTemplatesMocData);
  // const talentTemplates = getSnapshot(templateTalentMocData);

  // console.log('snapshot 7777', employerTemplates);
  // console.log('talentTemplates 7777', talentTemplates);

  const sendMessage = (message, sender) => {
    if (message.trim()) {
      const newMessage = { sender, message, time: new Date().toLocaleTimeString() };
      setMessages(newMessage);
      setInputMessage('');
    }
  };

  const online = true;
  const read = true;

  console.log('messages', getSnapshot(messages));

  return (
    <div className={styles.chatEmployerTalent}>
      <header className={styles.header}>
        <Link to='/talent/jobs/2'>
          <img width={22} height={22} src={backArrow} alt='back arrow' />
        </Link>
        <div className={styles.avatarBox}>
          <img width={46} height={46} src={avatarAgency} alt='talent' />
          <div>
            <p className={styles.name}>
              SV Models <span className={online ? styles.online : styles.offline} />
            </p>
            <p className={styles.name}>Agency</p>
          </div>
        </div>
        <button className={styles.menuBtn} type='button'>
          <img src={iconMenu} alt='menu' />
        </button>
      </header>
      <main className={styles.main}>
        <Link to='/employer/chat/talent/2'>{t('generalChat.employer')}</Link>
        <p className={styles.date}>23.02.2023</p>
        <section className={styles.messageBox}>
          <h4 className={styles.title}>{t('generalChat.welcome')}</h4>
          <p className={styles.desc}>{t('generalChat.template')}</p>
        </section>

        <section className={styles.messageContainer}>
          {templateTalentMocData.map((template, index) => (
            <article key={index}>
              <div className={styles.messageBox}>
                <h4 className={styles.title}>{t('generalChat.title')}</h4>
                <p className={styles.desc}>{template}</p>
              </div>
              <button
                className={styles.sendBtn}
                type='button'
                onClick={() => sendMessage(template, t('generalChat.talent'))}>
                <span>{t('generalChat.sendBtn')}</span>
                <img width={7} height={7} src={arrow} alt='arrow.svg' />
              </button>
            </article>
          ))}
        </section>

        {messages.length > 0 && (
          <div className={styles.messageContainer}>
            {messages.map((msg, index) => (
              <article key={index}>
                <div
                  className={`${styles.messageBoxTalent} ${
                    msg.sender === t('generalChat.talent') ? styles.talentMsg : styles.employerMsg
                  }`}>
                  <p className={styles.title}>{msg.sender}</p>
                  <p className={styles.desc}>{msg.message}</p>
                  <p className={styles.time}>
                    {msg.time}
                    {read && <img src={checkmarks} alt='checkmarks.svg' />}
                  </p>
                </div>
                {msg.sender === t('generalChat.employer') && (
                  <Link to='/talent/jobs/7' className={styles.checkJobBtn}>
                    <span>{t('generalChat.check')}</span>
                    <img width={7} height={7} src={arrow} alt='arrow.svg' />
                  </Link>
                )}
              </article>
            ))}
          </div>
        )}

        <label className={styles.sentMessageBox}>
          <input
            type='text'
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder={t('generalChat.sendPlaceholder')}
          />
          <button type='button' onClick={() => sendMessage(inputMessage, t('generalChat.talent'))}>
            <img src={letterIcon} alt='letter icon' />
          </button>
        </label>
      </main>

      <div className={styles.footerContainer}>
        <Footer />
      </div>
    </div>
  );
});

export default ChatEmployerTalent;
