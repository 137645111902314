import React, { useState } from 'react';
import Footer from '../../../components/Talent/Footer';
import iconEmployer from '../../../img/JobAlert/iconEmployer_1.jpg';
import iconEmployer2 from '../../../img/JobAlert/iconEmployer_2.jpg';
import heart from '../../../img/JobAlert/heart.svg';
import heartActive from '../../../img/JobAlert/heart_active.svg';
import chat from '../../../img/JobAlert/icon_chat.svg';

import styles from './jobAlert.module.scss';
import {useTranslation} from "react-i18next";

const jobAlertArray = [
  {
    id: '1',
    iconEmployer: iconEmployer,
    businessName: 'Club 360',
    city: 'Dubai',
    online: true,
    announcement: 'Urgently need two girls for an event tomorrow.',
  },
  {
    id: '2',
    iconEmployer: iconEmployer2,
    businessName: 'SV Models',
    city: 'Sharjah',
    online: false,
    announcement: 'Need a girl for a birthday party.',
  },
  {
    id: '3',
    iconEmployer: iconEmployer,
    businessName: 'Club 360',
    city: 'Dubai',
    online: true,
    announcement: 'Urgently need two girls for an event tomorrow.',
  },
  {
    id: '4',
    iconEmployer: iconEmployer2,
    businessName: 'SV Models',
    city: 'Sharjah',
    online: false,
    announcement: 'Need a girl for a birthday party.',
  },
];

const JobAlert = () => {
  const { t } = useTranslation('common');
  const [activeTab, setActiveTab] = useState('in-city');
  const [interesting, setInteresting] = useState(false);

  return (
    <div className={styles.jobAlert}>
      <header>
        <h1 className={styles.title}>{t('jobAlert.title')}</h1>
      </header>
      <main className={styles.main}>
        <div className={activeTab === 'other-city' ? styles.btnBoxSwitching : styles.btnBox}>
          <button
            className={`${styles.tabBtn} ${activeTab === 'in-city' && styles.tabBtnActive}`}
            type='button'
            onClick={() => setActiveTab('in-city')}>
            {t('jobAlert.city')}
          </button>
          <button
            className={`${styles.tabBtn} ${activeTab === 'other-city' && styles.tabBtnActive}`}
            type='button'
            onClick={() => setActiveTab('other-city')}>
            {t('jobAlert.otherCities')}
          </button>
        </div>
        {activeTab === 'in-city' && (
          <aside className={styles.jobAlertWrapper}>
            {jobAlertArray.map((item, index) => (
              <article className={styles.jobAlertContainer} key={index}>
                <article className={styles.header}>
                  <img className={styles.img} width={46} height={46} src={item.iconEmployer} alt='icon employer' />
                  <div className={styles.desc}>
                    <p className={styles.employerTitle}>{item.businessName}</p>
                    <p className={styles.onlineBox}>
                      {item.city} <span className={item.online ? styles.online : styles.offline} /> 5 hours
                    </p>
                  </div>
                </article>
                <p className={styles.announcement}>{item.announcement}</p>
                <div className={styles.interestingBox}>
                  <button
                    className={`${styles.announcementBtn} ${interesting && styles.active}`}
                    type='button'
                    onClick={() => setInteresting(!interesting)}>
                    <img width={19} height={18} src={interesting ? heartActive : heart} alt='heart' />
                    <span>{t('jobAlert.interesting')}</span>
                  </button>
                  <button className={styles.announcementBtn} type='button'>
                    <img width={19} height={18} src={chat} alt='chat' />
                    <span>{t('jobAlert.write')}</span>
                  </button>
                </div>
                <hr className={styles.line} />
              </article>
            ))}
          </aside>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default JobAlert;
