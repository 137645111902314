import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LinkButton from '../Talent/LinkButton';
import headerImage from '../../img/Home-page/header.png';
import cIcon from '../../img/Home-page/c_icon.png';
import cIconBlack from '../../img/Home-page/c_icon_black.svg';

import styles from './footerHomePage.module.scss';

const FooterHomePage = ({ footer }) => {
  const { t } = useTranslation('common');
  let date = new Date();

  const itemMenuArray = [
    { title: `${t('footer.about')}`, link: '/about-us' },
    { title: `${t('footer.faq')}`, link: '/faq' },
    { title: `${t('footer.career')}`, link: '/career' },
    { title: `${t('footer.contacts')}`, link: '/contact' },
    { title: `${t('footer.privacyPolicy')}` },
  ];

  return (
    <footer className={`${footer === 'homePage' ? styles.footer : ''}`}>
      {footer === 'homePage' && (
        <>
          <h4 className={styles.footerTitle}>{t('footer.title')}</h4>
          <img width={140} height={26} src={headerImage} alt='logo' />
          <p className={styles.footerDesc}>Job in entertainment</p>
          <LinkButton title={t('footer.joinLink')} link='/talent/create-profile-1' />
        </>
      )}
      <article className={`${footer === 'homePage' ? styles.footerMenu : styles.otherPage}`}>
        <nav className={styles.footerNav}>
          {itemMenuArray.map((item, index) => (
            <Link
              className={`${footer === 'homePage' ? styles.linkItem : styles.linkItemBlack}`}
              to={item.link}
              key={index}>
              {item.title}
            </Link>
          ))}
        </nav>
        <aside className={styles.footerRights}>
          <img width='10' height='10' src={footer === 'homePage' ? cIcon : cIconBlack} alt='rights' />
          <p className={`${footer === 'homePage' ? styles.footerRightsDesc : styles.footerRightsDescBlack}`}>
            {date.getFullYear()} PerfomX
          </p>
        </aside>
      </article>
    </footer>
  );
};

export default FooterHomePage;
