import React from 'react';
import Footer from '../../../components/Employer/Footer';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import settings from '../../../img/Profile/settings.svg';
import models from '../../../img/Profile/models.jpg';
import profileSettings from '../../../img/Profile/profile_settings.svg';
import pointsImg from '../../../img/Profile/points_img.svg';
import iconStar from '../../../img/Profile/icon_star.svg';
import dollar from '../../../img/Profile/dollar.svg';
import boostProfile from '../../../img/Profile/boost_profile.svg';
import becomeTalent from '../../../img/Profile/become_talent.svg';
import notification from '../../../img/Profile/notification.svg';
import getBonuses from '../../../img/Profile/get_bonuses.svg';
import jobAlert from '../../../img/Profile/job_alert.svg';
// import hourglass from '../../../img/Profile/hourglass.svg';

import styles from './profileEmployer.module.scss';

const ProfileEmployer = () => {
  const { t } = useTranslation('common');

  const optionsArray = [
    {
      iconOptions: boostProfile,
      link: '/employer/boost-profile',
      title: `${t('employerProfile.optionTitle1')}`,
      desc: `${t('employerProfile.optionDesc1')}`,
    },
    {
      iconOptions: becomeTalent,
      link: '/employer/become-a-top-talent',
      title: `${t('employerProfile.optionTitle2')}`,
      desc: `${t('employerProfile.optionDesc2')}`,
    },
    {
      iconOptions: notification,
      link: '/employer/receive-notifications',
      title: `${t('employerProfile.optionTitle3')}`,
      desc: `${t('employerProfile.optionDesc3')}`,
    },
    {
      iconOptions: getBonuses,
      link: '/employer/get-bonuses',
      title: `${t('employerProfile.optionTitle4')}`,
      desc: `${t('employerProfile.optionDesc4')}`,
    },
    {
      iconOptions: jobAlert,
      link: '/',
      title: `${t('employerProfile.optionTitle5')}`,
      desc: `${t('employerProfile.optionDesc5')}`,
    },
  ];

  return (
    <div className={styles.profile}>
      <header className={styles.header}>
        <img width={28} height={28} src={settings} alt='settings' />
        <article className={styles.iconBox}>
          <img width={128} height={128} src={models} alt='avatar talent' />
          <p className={styles.title}>
            SV Models / <span>Agency</span>
          </p>
          <Link className={styles.link} to='/employer/view-profile'>
            {t('employerProfile.view')}
          </Link>
        </article>
        <img width={28} height={28} src={profileSettings} alt='profile settings' />
      </header>
      <main className={styles.main}>
        <button className={styles.allMyJobs} type='button'>
          {t('employerProfile.myJobs')}
        </button>
        <section className={styles.accountOverview}>
          <article className={styles.points}>
            <img width={34} height={34} src={pointsImg} alt='points' />
            <p className={styles.pointTitle}>424</p>
            <p className={styles.pointDesc}>{t('employerProfile.positions')}</p>
          </article>
          <article className={styles.points}>
            <img width={34} height={34} src={iconStar} alt='star' />
            <p className={styles.pointTitle}>19</p>
            <p className={styles.pointDesc}>{t('employerProfile.favorites')}</p>
          </article>
          {/*<article className={styles.points}>
            <img width={34} height={34} src={hourglass} alt='hourglass' />
            <p className={styles.finishedTitle}>Finished</p>
            <p className={styles.finishedDesc}>Premium subscription</p>
          </article>*/}
        </section>
        <section className={styles.balance}>
          <img src={dollar} alt='dollar' />
          <div>
            <p className={styles.balanceDesc}>{t('employerProfile.balance')}</p>
            <p className={styles.balanceDesc}>$0</p>
          </div>
          <button className={styles.topUpBtn} type='button'>
            {t('employerProfile.topUp')}
          </button>
        </section>
        <section>
          <h2 className={styles.optionsTitle}>{t('employerProfile.options')}</h2>
          <article className={styles.optionsContainer}>
            {optionsArray.map((item, index) => (
              <Link to={item.link} className={styles.optionsBox} key={index}>
                <img src={item.iconOptions} alt={item.title} />
                <span>
                  <span className={styles.subTitle}>{item.title}</span>
                  <span className={styles.desc}>{item.desc}</span>
                </span>
              </Link>
            ))}
          </article>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default ProfileEmployer;
