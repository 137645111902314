import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Employer/Footer';
import backArrow from '../../../img/Chat/back_arrow.svg';
import iconMenu from '../../../img/Chat/icon_menu.svg';
import checkmarks from '../../../img/Chat/checkmarks.svg';
import arrow from '../../../img/Chat/arrow.svg';
import { useStore } from '../../../hooks/useRootStore';
import talentImg from '../../../img/Chat/talent_avatar.jpg';
import letterIcon from '../../../img/Chat/letter_icon.svg';
// import { getSnapshot } from 'mobx-state-tree';
import { observer } from 'mobx-react-lite';

import styles from './chatTalentEmployer.module.scss';

const ChatTalentEmployer = observer(() => {
  const { t } = useTranslation('common');

  const employerTemplatesMocData = [
    `${t('generalChat.template1')}`,
    `${t('generalChat.template2')}`,
    `${t('generalChat.template3')}`,
  ];

  const {
    chatStore: { messages, setMessages /*employerTemplatesMocData*/ },
  } = useStore();

  const [inputMessage, setInputMessage] = useState('');

  // const employerTemplates = getSnapshot(employerTemplatesMocData);
  // const talentTemplates = getSnapshot(templateTalentMocData);

  // console.log('snapshot 7777', employerTemplates);
  // console.log('talentTemplates 7777', talentTemplates);

  const sendMessage = (message, sender) => {
    if (message.trim()) {
      const newMessage = { sender, message, time: new Date().toLocaleTimeString() };
      setMessages(newMessage);
      setInputMessage('');
    }
  };

  const online = true;
  const read = true;

  return (
    <div className={styles.chatTalentEmployer}>
      <header className={styles.header}>
        <Link to='/employer/all-my-jobs'>
          <img width={22} height={22} src={backArrow} alt='back arrow' />
        </Link>
        <div className={styles.avatarBox}>
          <img width={46} height={46} src={talentImg} alt='talent' />
          <div>
            <p className={styles.name}>
              Sasha <span className={online ? styles.online : styles.offline} />
            </p>
            <p className={styles.name}>Dancer</p>
          </div>
        </div>
        <button className={styles.menuBtn} type='button'>
          <img src={iconMenu} alt='menu' />
        </button>
      </header>
      <main className={styles.main}>
        <Link to='/talent/chat/employer/4'>{t('generalChat.talent')}</Link>
        <p className={styles.date}>23.02.2023</p>
        <section className={styles.messageBox}>
          <h4 className={styles.title}>{t('generalChat.welcome')}</h4>
          <p className={styles.desc}>{t('generalChat.template')}</p>
        </section>
        <section className={styles.messageContainer}>
          {employerTemplatesMocData.map((template, index) => (
            <article key={index}>
              <div className={styles.messageBox}>
                <h4 className={styles.title}>{t('generalChat.title')}</h4>
                <p className={styles.desc}>{template}</p>
              </div>
              <button className={styles.sendBtn} onClick={() => sendMessage(template, t('generalChat.employer'))}>
                <span>{t('generalChat.sendBtn')}</span>
                <img width={7} height={7} src={arrow} alt='arrow.svg' />
              </button>
            </article>
          ))}
        </section>

        {messages.length > 0 && (
          <div className={styles.messageContainer}>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`${styles.messageBoxEmployer} ${
                  msg.sender === t('generalChat.employer') ? styles.employerMsg : styles.talentMsg
                }`}>
                <p className={styles.title}>{msg.sender}</p>
                <p className={styles.desc}>{msg.message}</p>
                <p className={styles.time}>
                  {msg.time}
                  {read && <img src={checkmarks} alt='checkmarks.svg' />}
                </p>
              </div>
            ))}
          </div>
        )}

        <label className={styles.sentMessageBox}>
          <input
            type='text'
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder={t('generalChat.sendPlaceholder')}
          />
          <button type='button' onClick={() => sendMessage(inputMessage, t('generalChat.employer'))}>
            <img src={letterIcon} alt='letter icon' />
          </button>
        </label>
      </main>
      <div className={styles.footerContainer}>
        <Footer />
      </div>
    </div>
  );
});

export default ChatTalentEmployer;
