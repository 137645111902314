import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import jobs from '../../../img/Footer/job.svg';
import jobActive from '../../../img/Footer/job_active.svg';
import jobAlert from '../../../img/Footer/job_alert.svg';
import jobAlertActive from '../../../img/Footer/job_alert_active.svg';
import apply from '../../../img/Footer/apply.svg';
import applyActive from '../../../img/Footer/apply_active.svg';
import chat from '../../../img/Footer/chat.svg';
import chatActive from '../../../img/Footer/chat_active.svg';
import profile from '../../../img/Footer/profile.svg';
import profileActive from '../../../img/Footer/profile_active.svg';
import { useTranslation } from 'react-i18next';

import styles from './footer.module.scss';

const Footer = () => {
  const { t } = useTranslation('common');

  const routeToDescMap = {
    '/talent/jobs': `${t('footerTalent.jobs')}`,
    '/talent/jobs/': `${t('footerTalent.jobs')}`,
    '/talent/job-alert': `${t('footerTalent.jobAlert')}`,
    '/talent/apply': `${t('footerTalent.apply')}`,
    '/talent/chat': `${t('footerTalent.chat')}`,
    '/talent/chat/employer/': `${t('footerTalent.chat')}`,
    '/talent/profile': `${t('footerTalent.profile')}`,
    '/talent/view-profile': `${t('footerTalent.profile')}`,
    '/talent/boost-profile': `${t('footerTalent.profile')}`,
    '/talent/become-a-top-talent': `${t('footerTalent.profile')}`,
    '/talent/receive-notifications': `${t('footerTalent.profile')}`,
  };

  const menuFooterArray = [
    { desc: `${t('footerTalent.jobs')}`, img: jobs, imgActive: jobActive, link: '/talent/jobs' },
    { desc: `${t('footerTalent.jobAlert')}`, img: jobAlert, imgActive: jobAlertActive, link: '/talent/job-alert' },
    { desc: `${t('footerTalent.apply')}`, img: apply, imgActive: applyActive, link: '/talent/apply' },
    { desc: `${t('footerTalent.chat')}`, img: chat, imgActive: chatActive, link: '/talent/chat' },
    { desc: `${t('footerTalent.profile')}`, img: profile, imgActive: profileActive, link: '/talent/profile' },
  ];

  const { pathname } = useLocation();

  // routeToDescMap[pathname] - если ключь pathname созпадает с ключом /vacancies, то возращается значение - Jobs
  const getActiveItem = (desc) => {
    if (
      (desc === t('footerTalent.jobs') && pathname.startsWith('/talent/jobs/')) ||
      (desc === t('footerTalent.chat') && pathname.startsWith('/talent/chat/employer/'))
    ) {
      return true;
    }
    return routeToDescMap[pathname] === desc;
  };

  return (
    <footer className={styles.footer}>
      <nav className={styles.footerContainer}>
        {menuFooterArray.map(({ desc, img, imgActive, link }, index) => {
          const isActive = getActiveItem(desc);
          return (
            <Link className={styles.box} to={link} key={index}>
              <img width={30} height={28} src={isActive ? imgActive : img} alt={desc} />
              <span className={isActive ? styles.descActive : styles.desc}>{desc}</span>
            </Link>
          );
        })}
      </nav>
    </footer>
  );
};

export default Footer;
