import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import talents from '../../../img/Footer/icon_talents.svg';
import talentsActive from '../../../img/Footer/icon_talents_active.svg';
import jobAlert from '../../../img/Footer/job_alert.svg';
import jobAlertActive from '../../../img/Footer/job_alert_active.svg';
import apply from '../../../img/Footer/apply.svg';
import applyActive from '../../../img/Footer/apply_active.svg';
import chat from '../../../img/Footer/chat.svg';
import chatActive from '../../../img/Footer/chat_active.svg';
import profile from '../../../img/Footer/profile.svg';
import profileActive from '../../../img/Footer/profile_active.svg';

import styles from './footer.module.scss';

const Footer = () => {
  const { t } = useTranslation('common');

  const { pathname } = useLocation();

  const menuFooterArray = [
    { desc: t('employerFooter.talent'), img: talents, imgActive: talentsActive, link: '/employer/talents' },
    { desc: t('employerFooter.jobAlert'), img: jobAlert, imgActive: jobAlertActive, link: '/employer/job-alert' },
    { desc: t('employerFooter.responses'), img: apply, imgActive: applyActive, link: '/employer/responses' },
    { desc: t('employerFooter.chat'), img: chat, imgActive: chatActive, link: '/employer/chat' },
    { desc: t('employerFooter.profile'), img: profile, imgActive: profileActive, link: '/employer/profile' },
  ];

  const routeToDescMap = {
    '/employer/talents': t('employerFooter.talent'),
    '/employer/talent/': t('employerFooter.talent'),
    '/employer/job-alert': t('employerFooter.jobAlert'),
    '/employer/responses': t('employerFooter.responses'),
    '/employer/chat': t('employerFooter.chat'),
    '/employer/chat/talent/:id': t('employerFooter.chat'),
    '/employer/profile': t('employerFooter.profile'),
    '/employer/view-profile': t('employerFooter.profile'),
    '/employer/all-my-jobs': t('employerFooter.talent'),
    '/talent/boost-profile': t('employerFooter.profile'),
    '/talent/become-a-top-talent': t('employerFooter.profile'),
    '/talent/receive-notifications': t('employerFooter.profile'),
  };

  // routeToDescMap[pathname] - если ключь pathname созпадает с ключом /vacancies, то возращается значение - Jobs
  const getActiveItem = (desc) => {
    if (
      (desc === t('employerFooter.talent') && pathname.startsWith('/employer/talent/')) ||
      (desc === t('employerFooter.chat') && pathname.startsWith('/employer/chat/talent/'))
    ) {
      return true;
    }
    return routeToDescMap[pathname] === desc;
  };

  return (
    <footer className={styles.footer}>
      <nav className={styles.footerContainer}>
        {menuFooterArray.map(({ desc, img, imgActive, link }, index) => {
          const isActive = getActiveItem(desc);
          return (
            <Link className={styles.box} to={link} key={index}>
              <img width={30} height={28} src={isActive ? imgActive : img} alt={desc} />
              <span className={isActive ? styles.descActive : styles.desc}>{desc}</span>
            </Link>
          );
        })}
      </nav>
    </footer>
  );
};

export default Footer;
