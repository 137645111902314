import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
import Footer from '../../../components/Talent/Footer';
import club from '../../../img/Jobs/club.jpg';
import club2 from '../../../img/Jobs/club_2.jpg';
import club3 from '../../../img/Jobs/club_3.jpg';
import backArrow from '../../../img/Jobs/back_arrow.svg';
import star from '../../../img/Jobs/star.svg';
import starActive from '../../../img/Jobs/star_active.svg';
import employerIcon from '../../../img/Jobs/employer_icon.jpg';
import { employerProfileObject } from './employerProfileArray';

import styles from './jobs.module.scss';

const jobsArray = [club, club2, club3, club, club2, club3, club, club2];

const Jobs = () => {
  const { t } = useTranslation('common');

  const [open, setOpen] = useState(false);
  const [applyForJob, setApplyForJob] = useState(false);

  const settings = {
    autoplay: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    dotsClass: styles.dots,
  };

  function generatePath(employerName) {
    return `/${employerName.toLowerCase().replace(/\s+/g, '-')}`;
  }

  // const path = generatePath(employerProfileObject.employerName);
  // console.log(path);
  const idEmployer = 4;

  return (
    <div className={styles.jobs}>
      <header className={styles.header}>
        <nav className={styles.navBox}>
          <Link to='/talent/jobs'>
            <img width={32} height={32} src={backArrow} alt='back arrow' />
          </Link>
          {employerProfileObject.favoriteEmployer ? (
            <img width={32} height={32} src={starActive} alt='star active' />
          ) : (
            <img width={32} height={32} src={star} alt='star' />
          )}
        </nav>
        <Slider {...settings} className={styles.sliderBox}>
          {jobsArray.map((item, index) => (
            <img width={390} height={362} src={item} alt='club.jpg' key={index} />
          ))}
        </Slider>
      </header>
      <main className={styles.main}>
        <Link className={styles.employerLink} to={`/employer${generatePath(employerProfileObject.businessName)}`}>
          <img width={60} height={60} src={employerIcon} alt='Employer icon' />
          <span className={styles.employerBox}>
            <span className={styles.employerTitle}>
              <span className={styles.employerTitleBolt}>{employerProfileObject.businessName} /</span> Agensy
            </span>
            <span className={styles.desc}>Turkey, Istanbul</span>
            <span className={`${styles.desc} ${styles.underline}`}>{t('employerVacancy.viewProfile')}</span>
          </span>
        </Link>
        <hr className={styles.line} />
        <h2 className={styles.title}>{t('employerVacancy.jobs')}</h2>
        <ul className={styles.box}>
          <li className={styles.desc}>
            {t('employerVacancy.placeWork')} <span className={styles.highlighted}>Club Space</span>
          </li>
          <li className={styles.desc}>
            {t('employerVacancy.location')} <span className={styles.highlighted}>Turkey, Istanbul</span>
          </li>
          <li className={styles.desc}>
            {t('employerVacancy.vacancy')} <span className={styles.highlighted}>Go-go dancer</span>
          </li>
        </ul>
        <h2 className={styles.title}>{t('employerVacancy.contractTerms')}</h2>
        <ul className={styles.box}>
          <li className={styles.desc}>
            {t('employerVacancy.salary')} <span className={styles.highlighted}>1500$</span>
          </li>
          <li className={styles.desc}>
            {t('employerVacancy.salaryBonuses')}{' '}
            <span className={styles.highlighted}>For cocktails and table dances</span>
          </li>
          <li className={styles.desc}>
            {t('employerVacancy.workingHours')} <span className={styles.highlighted}> From 8:00 pm to 3:00 am</span>
          </li>
          <li className={styles.desc}>
            {t('employerVacancy.break')} <span className={styles.highlighted}> No break</span>
          </li>
          <li className={styles.desc}>
            {t('employerVacancy.dayOff')} <span className={styles.highlighted}>Once a week</span>
          </li>
          <li className={styles.desc}>
            {t('employerVacancy.contractStart')} <span className={styles.highlighted}>From 26.03.24</span>
          </li>
          <li className={styles.desc}>
            {t('employerVacancy.contractDuration')} <span className={styles.highlighted}>From 6 months</span>
          </li>
        </ul>
        <h2 className={styles.title}>{t('employerVacancy.conditions')}</h2>
        <ul className={styles.box}>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Air ticket</span>
          </li>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Airport pickup</span>
          </li>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Visa</span>
          </li>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Transfer to and from work</span>
          </li>
          <li className={styles.desc}>
            {t('employerVacancy.accommodation')} <span className={styles.highlighted}> For 2 people</span>
          </li>
          <li className={styles.desc}>
            {t('employerVacancy.meals')} <span className={styles.highlighted}>Once a day</span>
          </li>
          <li className={styles.desc}>
            {t('employerVacancy.cuisine')} <span className={styles.highlighted}>Asian</span>
          </li>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Costumes/Uniform</span>
          </li>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Gym</span>
          </li>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Professional training</span>
          </li>
        </ul>
        <h2 className={styles.title}>{t('employerVacancy.additionTitle')}</h2>
        <article className={`${styles.text} ${open && styles.textOpen}`}>
          <p className={`${open ? styles.textBox : styles.ellipsis} ${styles.desc}`}>
            {t('employerVacancy.additionDesc')}
          </p>
        </article>
        <button className={`${styles.expand}`} onClick={() => setOpen(!open)} type='button'>
          {open ? t('employerVacancy.hide') : t('employerVacancy.expand')}
        </button>
        <div className={styles.btnBox}>
          <Link className={styles.chatBtn} to={`/talent/chat/employer/${idEmployer}`}>
            {t('employerVacancy.chatBtn')}
          </Link>
          <button className={styles.apply} type='button' onClick={() => setApplyForJob(!applyForJob)}>
            {t('employerVacancy.applyNowBtn')}
          </button>
        </div>
      </main>
      <Footer />
      {applyForJob && (
        <section className={styles.shareProfilePopUp}>
          <article className={styles.profilePopUp}>
            <h4 className={styles.profilePopUpTitle}>{t('applyNowPopUp.title')}</h4>
            <hr className={styles.line} />
            <div className={styles.profilePopUpBtnBox}>
              <button className={styles.profilePopUpBtn} type='button' onClick={() => setApplyForJob(!applyForJob)}>
                {t('applyNowPopUp.cancel')}
              </button>
              <button className={styles.profilePopUpBtn} type='button' onClick={() => setApplyForJob(!applyForJob)}>
                {t('applyNowPopUp.share')}
              </button>
            </div>
          </article>
        </section>
      )}
    </div>
  );
};

export default Jobs;
