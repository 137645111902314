import React from 'react';
import { useForm, FieldError } from 'react-hook-form';
import arrow from '../../../img/Ctrate-form/arrow-down.svg';
import arrowBack from '../../../img/Filter/back_arrow.svg';
import PopUpCreateProfile from '../../../components/Talent/PopUpCreateProfile';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './filter.module.scss';

interface FormValues {
  age: number;
  height: number;
  weight: number;
  gender: string;
  country: string;
  cities: string;
  workplace: string;
  vacancy: string;
  expectedSalary: string;
  appearance: string;
  languageSkills: string;
  contractDuration: string;
}

const Filter = () => {
  const { t } = useTranslation('common');

  const countries = [
    `${t('filterTalent.country1')}`,
    `${t('filterTalent.country2')}`,
    `${t('filterTalent.country3')}`,
    `${t('filterTalent.country4')}`,
    `${t('filterTalent.country5')}`,
    `${t('filterTalent.country6')}`,
    `${t('filterTalent.country7')}`,
    `${t('filterTalent.country8')}`,
  ];
  const cities = [
    `${t('filterTalent.city1')}`,
    `${t('filterTalent.city2')}`,
    `${t('filterTalent.city3')}`,
    `${t('filterTalent.city4')}`,
    `${t('filterTalent.city5')}`,
    `${t('filterTalent.city6')}`,
    `${t('filterTalent.city7')}`,
    `${t('filterTalent.city8')}`,
    `${t('filterTalent.city9')}`,
    `${t('filterTalent.city10')}`,
  ];
  const workplace = [
    `${t('filterTalent.workplace1')}`,
    `${t('filterTalent.workplace2')}`,
    `${t('filterTalent.workplace3')}`,
    `${t('filterTalent.workplace4')}`,
    `${t('filterTalent.workplace5')}`,
    `${t('filterTalent.workplace6')}`,
  ];
  const vacancy = [
    `${t('filterTalent.vacancy1')}`,
    `${t('filterTalent.vacancy2')}`,
    `${t('filterTalent.vacancy3')}`,
    `${t('filterTalent.vacancy4')}`,
    `${t('filterTalent.vacancy5')}`,
    `${t('filterTalent.vacancy6')}`,
    `${t('filterTalent.vacancy7')}`,
    `${t('filterTalent.vacancy8')}`,
    `${t('filterTalent.vacancy9')}`,
  ];
  const expectedSalaryArray = [
    `${t('filterTalent.vacancy1')}`,
    `${t('filterTalent.vacancy2')}`,
    `${t('filterTalent.vacancy3')}`,
    `${t('filterTalent.vacancy4')}`,
    `${t('filterTalent.vacancy5')}`,
    `${t('filterTalent.vacancy6')}`,
  ];
  const appearanceArray = [
    `${t('filterTalent.appearance1')}`,
    `${t('filterTalent.appearance2')}`,
    `${t('filterTalent.appearance3')}`,
    `${t('filterTalent.appearance4')}`,
    `${t('filterTalent.appearance5')}`,
  ];
  const languageSkillsArray = [
    `${t('filterTalent.language1')}`,
    `${t('filterTalent.language2')}`,
    `${t('filterTalent.language3')}`,
  ];
  const contractDurationArray = [
    `${t('filterTalent.contract1')}`,
    `${t('filterTalent.contract2')}`,
    `${t('filterTalent.contract3')}`,
    `${t('filterTalent.contract4')}`,
  ];

  const [genderActive, setGenderActive] = React.useState('');

  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [isCountriesPopupOpen, setIsCountriesPopupOpen] = React.useState(false);

  const [selectedCities, setSelectedCities] = React.useState('');
  const [isCitiesPopupOpen, setIsCitiesPopupOpen] = React.useState(false);

  const [selectedWorkplace, setSelectedWorkplace] = React.useState('');
  const [isWorkplacePopupOpen, setIsWorkplacePopupOpen] = React.useState(false);

  const [selectedVacancies, setSelectedVacancies] = React.useState('');
  const [isVacanciesPopupOpen, setIsVacanciesPopupOpen] = React.useState(false);

  const [selectedExpectedSalary, setSelectedExpectedSalary] = React.useState('');
  const [isExpectedSalaryPopupOpen, setIsExpectedSalaryPopupOpen] = React.useState(false);

  const [selectedAppearance, setSelectedAppearance] = React.useState('');
  const [isAppearancePopupOpen, setIsAppearancePopupOpen] = React.useState(false);

  const [selectedLanguageSkills, setSelectedLanguageSkills] = React.useState('');
  const [isLanguageSkillsPopupOpen, setIsLanguageSkillsPopupOpen] = React.useState(false);

  const [selectedContractDuration, setSelectedContractDuration] = React.useState('');
  const [isContractDurationPopupOpen, setIsContractDurationPopupOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
    watch,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      gender: '',
      country: '',
      cities: '',
      workplace: '',
      vacancy: '',
      expectedSalary: '',
      appearance: '',
      languageSkills: '',
      contractDuration: '',
    },
  });

  const navigate = useNavigate();
  // const genderValue = watch('gender');

  const onSubmit = (data: FormValues) => {
    // navigate('/talent/jobs');
    navigate('/talent/parameters');
    console.log('Form data:', data);
  };

  const handleGenderClick = (gender: string) => {
    setGenderActive(gender);
    setValue('gender', gender);
  };

  const handleReset = () => {
    reset();
    setGenderActive('');
    setSelectedCountry('');
    setSelectedCities('');
    setSelectedWorkplace('');
    setSelectedVacancies('');
    setSelectedExpectedSalary('');
    setSelectedAppearance('');
    setSelectedLanguageSkills('');
    setSelectedContractDuration('');
  };

  return (
    <section className={styles.createProfileJobApplication}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <article className={styles.titleBox}>
          <Link to='/talent/jobs'>
            <img width={22} height={22} src={arrowBack} alt='arrow back' />
          </Link>
          <h1 className={styles.title}>{t('filterTalent.title')}</h1>
          <button className={styles.reset} type='button' onClick={handleReset}>
            {t('filterTalent.reset')}
          </button>
        </article>
        <article className={styles.labelContainer}>
          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder={t('filterTalent.country')}
              value={selectedCountry}
              onClick={() => setIsCountriesPopupOpen(true)}
              readOnly
              {...register('country', { required: t('filterTalent.countryError') })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.country && <p className={styles.errorMessage}>{errors.country.message}</p>}

          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder={t('filterTalent.cities')}
              value={selectedCities}
              onClick={() => setIsCitiesPopupOpen(true)}
              readOnly
              {...register('cities', { required: t('filterTalent.citiesError') })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.cities && <p className={styles.errorMessage}>{errors.cities.message}</p>}

          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder={t('filterTalent.workplace')}
              value={selectedWorkplace}
              onClick={() => setIsWorkplacePopupOpen(true)}
              readOnly
              {...register('workplace', { required: t('filterTalent.workplaceError') })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.workplace && <p className={styles.errorMessage}>{errors.workplace.message}</p>}

          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder={t('filterTalent.vacancy')}
              value={selectedVacancies}
              onClick={() => setIsVacanciesPopupOpen(true)}
              readOnly
              {...register('vacancy', { required: t('filterTalent.vacancyError') })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.vacancy && <p className={styles.errorMessage}>{errors.vacancy.message}</p>}

          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder={t('filterTalent.salary')}
              value={selectedExpectedSalary}
              onClick={() => setIsExpectedSalaryPopupOpen(true)}
              readOnly
              {...register('expectedSalary', { required: t('filterTalent.salaryError') })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.expectedSalary && <p className={styles.errorMessage}>{errors.expectedSalary.message}</p>}
        </article>
        <article>
          <h3 className={styles.subTitle}>{t('filterTalent.gender')}</h3>
          <aside className={styles.genderBtnBox}>
            <label className={`${styles.genderBtn} ${genderActive === 'male' && styles.genderSelection}`}>
              <input
                className={styles.createProfileInput}
                type='radio'
                value='male'
                {...register('gender', { required: t('filterTalent.genderError') })}
                onClick={() => handleGenderClick('male')}
                checked={genderActive === 'male'}
                hidden
              />
              {t('filterTalent.male')}
            </label>

            <label className={`${styles.genderBtn} ${genderActive === 'female' && styles.genderSelection}`}>
              <input
                className={styles.createProfileInput}
                type='radio'
                value='female'
                {...register('gender', { required: t('filterTalent.genderError') })}
                onClick={() => handleGenderClick('female')}
                checked={genderActive === 'female'}
                hidden
              />
              {t('filterTalent.female')}
            </label>
          </aside>
          {errors.gender && <p className={styles.errorMessage}>{errors.gender.message}</p>}
        </article>
        <article className={styles.labelContainer}>
          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder={t('filterTalent.appearance')}
              value={selectedAppearance}
              onClick={() => setIsAppearancePopupOpen(true)}
              readOnly
              {...register('appearance', { required: t('filterTalent.appearanceError') })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.appearance && <p className={styles.errorMessage}>{errors.appearance.message}</p>}

          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder={t('filterTalent.languageSkills')}
              value={selectedLanguageSkills}
              onClick={() => setIsLanguageSkillsPopupOpen(true)}
              readOnly
              {...register('languageSkills', { required: t('filterTalent.languageSkillsError') })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.languageSkills && <p className={styles.errorMessage}>{errors.languageSkills.message}</p>}

          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder={t('filterTalent.contract')}
              value={selectedContractDuration}
              onClick={() => setIsContractDurationPopupOpen(true)}
              readOnly
              {...register('contractDuration', { required: t('filterTalent.contractError') })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.contractDuration && <p className={styles.errorMessage}>{errors.contractDuration.message}</p>}
        </article>
        <p className={styles.desc}>{t('filterTalent.desc')}</p>
        <button className={styles.submitBtn} type='submit'>
          {t('filterTalent.applyBtn')}
        </button>
      </form>
      <PopUpCreateProfile
        title={t('filterTalent.country')}
        popupOpen={isCountriesPopupOpen}
        setPopupOpen={setIsCountriesPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountry(value);
          setValue('country', value);
        }}
        selectedObject={selectedCountry}
        itemArray={countries}
      />
      <PopUpCreateProfile
        title={t('filterTalent.cities')}
        popupOpen={isCitiesPopupOpen}
        setPopupOpen={setIsCitiesPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCities(value);
          setValue('cities', value);
        }}
        selectedObject={selectedCities}
        itemArray={cities}
      />
      <PopUpCreateProfile
        title={t('filterTalent.workplace')}
        popupOpen={isWorkplacePopupOpen}
        setPopupOpen={setIsWorkplacePopupOpen}
        setSelectedObject={(value) => {
          setSelectedWorkplace(value);
          setValue('workplace', value);
        }}
        selectedObject={selectedWorkplace}
        itemArray={workplace}
      />
      <PopUpCreateProfile
        title={t('filterTalent.vacancy')}
        popupOpen={isVacanciesPopupOpen}
        setPopupOpen={setIsVacanciesPopupOpen}
        setSelectedObject={(value) => {
          setSelectedVacancies(value);
          setValue('vacancy', value);
        }}
        selectedObject={selectedVacancies}
        itemArray={vacancy}
      />
      <PopUpCreateProfile
        title={t('filterTalent.salary')}
        popupOpen={isExpectedSalaryPopupOpen}
        setPopupOpen={setIsExpectedSalaryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedExpectedSalary(value);
          setValue('expectedSalary', value);
        }}
        selectedObject={selectedExpectedSalary}
        itemArray={expectedSalaryArray}
      />
      <PopUpCreateProfile
        title={t('filterTalent.appearance')}
        popupOpen={isAppearancePopupOpen}
        setPopupOpen={setIsAppearancePopupOpen}
        setSelectedObject={(value) => {
          setSelectedAppearance(value);
          setValue('appearance', value);
        }}
        selectedObject={selectedAppearance}
        itemArray={appearanceArray}
      />
      <PopUpCreateProfile
        title={t('filterTalent.languageSkills')}
        popupOpen={isLanguageSkillsPopupOpen}
        setPopupOpen={setIsLanguageSkillsPopupOpen}
        setSelectedObject={(value) => {
          setSelectedLanguageSkills(value);
          setValue('languageSkills', value);
        }}
        selectedObject={selectedLanguageSkills}
        itemArray={languageSkillsArray}
      />
      <PopUpCreateProfile
        title={t('filterTalent.contract')}
        popupOpen={isContractDurationPopupOpen}
        setPopupOpen={setIsContractDurationPopupOpen}
        setSelectedObject={(value) => {
          setSelectedContractDuration(value);
          setValue('contractDuration', value);
        }}
        selectedObject={selectedContractDuration}
        itemArray={contractDurationArray}
      />
    </section>
  );
};

export default Filter;
